/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import MainTitle from '../../Components/MainTitle';
import NewCarCard from '../../Components/NewCarCard';
import styles from '../../Css/Schedule.module.css';
import sstyles from '../../Css/useCalendar.module.css';
import useAdminLogin from '../../Middle/useAdminLogin';
import { settingI } from './ProductList';

const arrowSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000">
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M15.61 7.41L14.2 6l-6 6 6 6 1.41-1.41L11.03 12l4.58-4.59z" />
  </svg>
);

const checkSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#00000029">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
  </svg>
);

const dayString = ['일', '월', '화', '수', '목', '금', '토'];

const Schedule = function () {
  const location = useLocation();
  const navigate = useNavigate();
  const { loginData } = useAdminLogin();
  const [YearMonth, setYearMonth] = useState<
  [number, number]>([(new Date()).getFullYear(), (new Date()).getMonth() + 1]);
  const [MonthData, setMonthData] = useState<boolean[]>([]);
  const isAvailable = !!(location.state && ('data' in (location.state as any)));
  const { data: tmpMonthData, mutate } = useSWR(isAvailable && `https://api.campingshare.co.kr/admin/product/available/list/${(location.state as { data: settingI }).data.id}?month=${YearMonth[0]}-${YearMonth[1] < 10 ? `0${YearMonth[1]}` : YearMonth[1]}`, async (url: string) => {
    const res = await axios.get<boolean[]>(url, loginData?.login ? loginData.axiosConfig : {});
    return res.data;
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  useEffect(() => {
    if (tmpMonthData) setMonthData(tmpMonthData);
  }, [tmpMonthData]);
  const changeMonth = (diff: number) => {
    let year = YearMonth[0];
    let month = YearMonth[1];
    month += diff;
    if (month < 1) {
      month += 12;
      year -= 1;
    } else if (month > 12) {
      month -= 12;
      year += 1;
    }
    setYearMonth([year, month]);
  };
  if (!isAvailable) navigate('/');
  if (!isAvailable) return <div />;
  const { data } = (location.state as any as { data: settingI });
  const dayAdder = (new Date(YearMonth[0], YearMonth[1] - 1, 1).getDay());
  const setAvailable = (idx: number, newValue: boolean) => {
    if (MonthData[idx] === newValue) return;
    const newMonthData = [...MonthData];
    newMonthData[idx] = newValue;
    setMonthData(newMonthData);
  };
  const onPush = async () => {
    try {
      await axios.post(`https://api.campingshare.co.kr/admin/product/available/modify/${data.id}`, {
        month: `${YearMonth[0]}-${YearMonth[1] < 10 ? `0${YearMonth[1]}` : YearMonth[1]}`,
        available: MonthData,
      }, loginData?.login ? loginData.axiosConfig : {});
      alert('저장되었습니다.');
      mutate();
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  return (
    <div className={styles.scheduleDiv}>
      <MainTitle>차량별 일정 설정</MainTitle>
      <NewCarCard info={{
        title: data.title, tags: [], like: -1, image: data.image, reviewRate: 9.9, reviewPeople: 999, place: data.location_details, driver: data.license, people: data.seats, pet: data.pet === 1, delivery: data.delivery === 1, price: -1, priceInfo: '이렇게 보입니다', discountInfo: { hasDiscount: false }, onLike: () => {},
      }}
      />
      <div className={`${sstyles.calendarButtonDiv} ${styles.calendarDiv}`}>
        <button type="button" onClick={() => changeMonth(-1)}>{arrowSvg}</button>
        <div>{`${YearMonth[0]}년 ${YearMonth[1]}월`}</div>
        <button type="button" onClick={() => changeMonth(1)}>{arrowSvg}</button>
      </div>
      <div className={styles.warning}>
        <div>다른 월로 이동하기 전에</div>
        <div>변경사항을 꼭 저장하세요!!</div>
      </div>
      <div className={styles.dateListDiv}>
        {
          MonthData.map((available, i) => (
            <div className={styles.eachDateDiv}>
              <div className={styles.dateInfoDiv}>
                <div className={styles.dateNum}>{i + 1}</div>
                <div className={styles.dateStr}>{`(${dayString[(i + dayAdder) % 7]})`}</div>
              </div>
              <div className={styles.clickerDiv}>
                <div onClick={() => setAvailable(i, false)} className={`${styles.eachClicker} ${!available && styles.active}`}>
                  {checkSvg}
                  <div>불가</div>
                </div>
                <div onClick={() => setAvailable(i, true)} className={`${styles.eachClicker} ${available && styles.active}`}>
                  {checkSvg}
                  <div>가능</div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
      <button onClick={onPush} className={styles.button} type="button">저장하기</button>
    </div>
  );
};

export default Schedule;
