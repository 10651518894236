import React from 'react';
import Customer from './Customer';
import Admin from './Admin';
import './App.css';

const App = function () {
  const openAdmin = false;
  if (window.location.host.startsWith('admin.') || openAdmin) {
    return <Admin />;
  }
  return <Customer />;
};

export default App;
