/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ko } from 'date-fns/locale';
import React, { useState } from 'react';
import ReactDatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import styles from '../Css/useCalendar.module.css';

const arrowSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000">
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M15.61 7.41L14.2 6l-6 6 6 6 1.41-1.41L11.03 12l4.58-4.59z" />
  </svg>
);

export default function useCalendar() {
  const [DateObj, setDateObj] = useState<Date|null>(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [DateCallback, setDateCallback] = useState<((date: Date|null) => void)>(
    () => ((date: Date|null) => {}));
  const [MinDate, setMinDate] = useState<Date|null>(new Date());
  const [MaxDate, setMaxDate] = useState<Date|null>(null);
  const [Exclude, setExclude] = useState<Date[]>([]);
  function openCalendar(info: {
    dateCallback: (date: Date|null) => void,
    startDate?: Date|null,
    minDate?: Date|null,
    maxDate?: Date|null,
    exclude?: Date[]
  }) {
    const {
      dateCallback, startDate, maxDate, minDate, exclude,
    } = info;
    setDateObj(startDate || new Date());
    setMaxDate(maxDate || null);
    setMinDate(minDate || new Date());
    setDateCallback(() => dateCallback);
    setExclude(exclude || []);
    setIsOpen(true);
  }
  function closeCalendar() {
    setIsOpen(false);
  }
  const renderCustomHeader = (props: ReactDatePickerCustomHeaderProps) => {
    const {
      monthDate,
      decreaseMonth, increaseMonth,
      prevMonthButtonDisabled, nextMonthButtonDisabled,
    } = props;
    return (
      <div className={styles.monthPickerDiv}>
        <button type="button" className={`${prevMonthButtonDisabled && styles.btnDisable}`} onClick={() => { if (prevMonthButtonDisabled) return; decreaseMonth(); }}>{arrowSvg}</button>
        <div className={styles.monthPickerText}>{`${monthDate.getFullYear()}년 ${monthDate.getMonth() + 1}월`}</div>
        <button type="button" className={`${nextMonthButtonDisabled && styles.btnDisable}`} onClick={() => { if (nextMonthButtonDisabled) return; increaseMonth(); }}>{arrowSvg}</button>
      </div>
    );
  };
  const calendar = (
    <ReactDatePicker
      selected={DateObj}
      onChange={(dt) => {
        setIsOpen(false);
        setDateObj(dt);
        DateCallback(dt);
      }}
      locale={ko}
      inline
      minDate={MinDate}
      maxDate={MaxDate}
      renderCustomHeader={renderCustomHeader}
      excludeDates={Exclude}
      disabledKeyboardNavigation
    />
  );
  const component = (
    <div onClick={() => setIsOpen(false)} className={`${styles.calendarDiv} ${isOpen || styles.calendarClose}`}>
      <div onClick={(e) => e.stopPropagation()}>{calendar}</div>
    </div>
  );
  return {
    calendarComponent: component, openCalendar, closeCalendar,
  };
}
