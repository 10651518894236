import React from 'react';

const BlankMaker = function (props: {
  width?: number,
  height?: number
}) {
  const { width, height } = props;
  return (
    <div style={{
      width: width ? `${width}px` : '1px',
      height: height ? `${height}px` : '1px',
    }}
    />
  );
};

export default BlankMaker;
