/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import styles from '../Css/useMenu.module.css';

export const useMenu = () => {
  const [Open, setOpen] = useState(false);
  const component = function (props: {children: any}) {
    return (
      <div onClick={() => setOpen(false)} className={`${styles.menuDiv} ${(!Open) && styles.menuClose}`}>
        <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>{props.children}</div>
      </div>
    );
  };
  return {
    Open, setOpen, MenuComponent: component,
  };
};

export default useMenu;
