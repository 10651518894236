import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EachTitle from '../../Components/EachTitle';
import useLogin from '../../Middle/useLogin';

const Logout = function () {
  const { loginMutate } = useLogin();
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem('jwt');
    loginMutate();
    navigate('/');
  }, []);
  return (
    <div>
      <EachTitle>잠시만 기다려주세요</EachTitle>
    </div>
  );
};

export default Logout;
