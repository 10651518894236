import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Error = function () {
  const navigate = useNavigate();
  useEffect(() => {
    alert('오류가 발생했습니다.');
    navigate('/');
  }, []);

  return (
    <div />
  );
};

export default Error;
