export function timeCompare(bigger: string, smaller: string) {
  const biggerLis = bigger.split(':');
  const biggerHour = parseInt(biggerLis[0], 10);
  const biggerMinute = parseInt(biggerLis[1], 10);
  const smallerLis = smaller.split(':');
  const smallerHour = parseInt(smallerLis[0], 10);
  const smallerMinute = parseInt(smallerLis[1], 10);
  if (biggerHour === smallerHour) return biggerMinute >= smallerMinute;
  return biggerHour > smallerHour;
}

export default timeCompare;
