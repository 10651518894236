/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import axios from 'axios';
import useLogin from '../../Middle/useLogin';
import EachTitle from '../../Components/EachTitle';
import HorizontalLine from '../../Components/HorizontalLine';
import IconAndText from '../../Components/IconAndText';
import LittleTitleAndText from '../../Components/LittleTitleAndText';
import useSelectDate from '../../Components/useSelectDate';
import TwoTable from '../../Components/TwoTable';
import getDateDiff from '../../Middle/getDateDiff';
import getDateString from '../../Middle/getDateString';
import ProductBottom from '../../Components/ProductBottom';
import useModal from '../../Components/useModal';
import styles from '../../Css/Product.module.css';
import BlankMaker from '../../Components/BlankMaker';
import useLike from '../../Middle/useLike';
import NewCarCard, { filledStar } from '../../Components/NewCarCard';
import ContentShow from '../../Components/ContentShow';
import iconInfo, { getIconURL, iconSpliter } from '../../Meta/icon';

export interface productI {
  'product': {
    'price': number,
    'id': number,
    'title': string,
    'contact': string,
    'deliver_start': string,
    'deliver_end': string,
    'return_end': string,
    'delivery': number,
    'location': number,
    'location_details': string,
    'like': boolean,
    delivery_warn: string,
    driver_warn: string
    'feature': string[],
    'adminNickname': string,
    'originPrice': number,
    'add_hour_price': number,
    max_hour_add: number
    'discountPercentage': number,
    'discount': string,
    'available': boolean,
    'image': string,
    'license': string,
    'seats': number,
    'pet': number,
    concatenation_discount_2n_price: number
    concatenation_discount_2n_type: number
    concatenation_discount_3n_price: number
    concatenation_discount_3n_type: number
    concatenation_discount_4n_price: number
    concatenation_discount_4n_type: number
    concatenation_discount_5n_price: number
    concatenation_discount_5n_type: number
    equipment_details: string
    refund: string,
    terms: string,
    reviewavg: number,
    reviewcnt: number,
  },
  'details': string,
  'equipment': {
    'id': number,
    'title': string,
    'price': number,
    'details': string
  }[],
  'option': {
    'id': number,
    'title': string
  }[]
}

const Product = function () {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const {
    SelectDate, LeftDate, RightDate, setLeftDate, setRightDate,
  } = useSelectDate(id);
  const doLike = useLike();
  const [AddTime, setAddTime] = useState(0);
  const [Hide, setHide] = useState(true);
  useEffect(() => {
    const start = searchParams.get('start');
    const end = searchParams.get('end');
    if (start && end) {
      setLeftDate(new Date(start));
      setRightDate(new Date(end));
    }
  }, [searchParams]);
  const { loginData } = useLogin();
  const dateDiff = getDateDiff(LeftDate, RightDate);
  const { setOpen, ModalComponent } = useModal();
  const { data, mutate } = useSWR(`https://api.campingshare.co.kr/guest/product/${id}${dateDiff >= 1 ? `?start=${getDateString(LeftDate as Date)}&end=${getDateString(RightDate as Date)}` : ''}`, async (url: string) => {
    const res = await axios.get<productI>(url, loginData?.login ? loginData.axiosConfig : {});
    return res.data;
  });
  const { data: reviewData } = useSWR(`https://api.campingshare.co.kr/guest/review/list/${id}`, async (url) => {
    const res = await axios.get<{
      score: number, details: string, reviewed_date: string
    }[]>(url);
    return res.data;
  });
  if (!data) return <EachTitle>로딩중</EachTitle>;
  const changeAddTime = (diff: number) => {
    const newAddTime = AddTime + diff;
    if (
      newAddTime >= 0
      && newAddTime <= 23
      && newAddTime <= data.product.max_hour_add
    ) setAddTime(newAddTime);
  };
  return (
    <>
      <NewCarCard
        info={{
          title: data.product.title,
          image: data.product.image,
          place: data.product.location_details,
          tags: data.product.feature,
          price: data.product.price,
          priceInfo: dateDiff > 0 ? `${dateDiff}박` : '1박',
          like: data.product.like ? 1 : 0,
          onLike: () => doLike(data.product.id, data.product.like, () => mutate()),
          reviewRate: data.product.reviewavg,
          reviewPeople: data.product.reviewcnt,
          driver: data.product.license,
          people: data.product.seats,
          pet: data.product.pet === 1,
          delivery: data.product.delivery === 1,
          discountInfo: data.product.discountPercentage > 0
            ? {
              hasDiscount: true,
              beforePrice: data.product.originPrice,
              discountRate: data.product.discountPercentage,
              discountName: data.product.discount,
            }
            : {
              hasDiscount: false,
            },
        }}
      />
      {/* <CarCard
        type="product"
        info={{
          company: data.product.adminNickname,
          name: data.product.title,
          imageUrl: data.product.image,
          place: data.product.location_details,
          tags: data.product.feature,
          nowPrice: data.product.price,
          andSlash: dateDiff > 0 ? `/ ${dateDiff}박` : '/ 1박',
          liked: data.product.like,
        }}
        onLike={() => doLike(data.product.id, data.product.like, () => mutate())}
        discountInfo={
          data.product.discountPercentage > 0
            ? {
              hasDiscount: true,
              beforePrice: data.product.originPrice,
              discountPercent: data.product.discountPercentage,
              discountName: data.product.discount,
            }
            : {
              hasDiscount: false,
            }
        }
      /> */}
      <HorizontalLine marginTop={0} marginBottom={15} />
      {SelectDate}
      <HorizontalLine marginTop={15} marginBottom={15} />
      <IconAndText
        icon={(
          <svg viewBox="0 0 24 24">
            <path fill="currentColor" d="M12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C6.47,22 2,17.5 2,12A10,10 0 0,1 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z" />
          </svg>
        )}
        boldText="추가 대여시간"
        lightText={`${AddTime}시간`}
        onClick={() => setOpen(true)}
      />
      <ModalComponent>
        <div className={styles.timeModalDiv}>
          <EachTitle alignCenter marginTop={30}>추가 대여시간을 선택해주세요</EachTitle>
          <div className={styles.setTimeDiv}>
            <button type="button" onClick={() => changeAddTime(-1)}>-</button>
            <div>{`${AddTime}시간`}</div>
            <button type="button" onClick={() => changeAddTime(1)}>+</button>
          </div>
          <button type="button" className={styles.timeCloseBtn} onClick={() => setOpen(false)}>닫기</button>
        </div>
      </ModalComponent>

      <HorizontalLine marginTop={15} marginBottom={15} />
      <LittleTitleAndText title="인수 시간" content={`${data.product.deliver_start} ~ ${data.product.deliver_end} 까지`} />
      <LittleTitleAndText title="반납 시간" content={`${data.product.return_end} 까지`} />

      <HorizontalLine marginTop={0} marginBottom={0} />
      <EachTitle marginTop={15}>연박 할인</EachTitle>
      <TwoTable leftList={['2박', '3박', '4박', '5박 이상']} rightList={[`${data.product.concatenation_discount_2n_price.toLocaleString()}${data.product.concatenation_discount_2n_type === 0 ? '%' : '원'}`, `${data.product.concatenation_discount_3n_price.toLocaleString()}${data.product.concatenation_discount_3n_type === 0 ? '%' : '원'}`, `${data.product.concatenation_discount_4n_price.toLocaleString()}${data.product.concatenation_discount_4n_type === 0 ? '%' : '원'}`, `${data.product.concatenation_discount_5n_price.toLocaleString()}${data.product.concatenation_discount_5n_type === 0 ? '%' : '원'}`]} />

      <HorizontalLine marginTop={15} marginBottom={0} />
      <EachTitle marginTop={15}>캠핑카 소개</EachTitle>
      <ContentShow data={data.details} />

      <HorizontalLine marginTop={15} marginBottom={0} />
      <EachTitle marginTop={15}>보유 시설</EachTitle>
      <div className={styles.tagsDiv}>
        {
          data.option.map((d) => {
            if (d.title.startsWith(iconSpliter)) {
              try {
                const key = d.title.split(iconSpliter)[1];
                return (
                  <div className={styles.eachOptionDiv}>
                    <div className={styles.optionName}>{iconInfo[key]}</div>
                    <div className={styles.optionImg}>
                      <img src={getIconURL(key)} alt={iconInfo[key]} />
                    </div>
                  </div>
                );
              } catch {
                // pass
              }
            }
            return (
              <div className={styles.eachOptionDiv}>
                <div className={styles.optionName}>{d.title}</div>
              </div>
            );
          })
        }
      </div>

      <HorizontalLine marginTop={15} marginBottom={0} />
      <EachTitle marginTop={15}>대여 가능 캠핑 장비</EachTitle>
      {/* <WidthReactImg url={carImg} /> */}
      <div className={`${styles.hideShowDiv} ${Hide && styles.hide}`}>
        <div className={styles.hideInner}>
          <ContentShow data={data.product.equipment_details} />
        </div>
        <button type="button" onClick={() => setHide((d) => !d)}>{Hide ? '자세히 보기' : '간단히 보기'}</button>
      </div>
      <TwoTable
        leftList={data.equipment.map((info) => info.title)}
        rightList={data.equipment.map((info) => `${info.price.toLocaleString('ko-KR')}원`)}
        leftBold
        marginTop={10}
      />

      <HorizontalLine marginTop={15} marginBottom={0} />
      <EachTitle marginTop={15}>딜리버리 가능 지역 안내</EachTitle>
      <LittleTitleAndText
        title=""
        content={data.product.delivery_warn}
      />

      <HorizontalLine marginTop={15} marginBottom={0} />
      <EachTitle marginTop={15}><span style={{ color: '#FF3636' }}>필독사항</span></EachTitle>
      <details className={styles.mustReadDiv}>
        <summary>운전자 대여 조건</summary>
        <div>{data.product.driver_warn}</div>
      </details>
      <details className={styles.mustReadDiv}>
        <summary>취소 관련 규정</summary>
        <div>{data.product.refund}</div>
      </details>
      <details className={styles.mustReadDiv}>
        <summary>자동차 보험</summary>
        <div>{data.product.terms}</div>
      </details>
      <HorizontalLine marginTop={15} marginBottom={0} />
      <EachTitle marginTop={15}>{`사용자 후기${reviewData ? ` (${reviewData.length})` : ''}`}</EachTitle>
      {
        reviewData?.map((d) => (
          <div className={styles.reviewEachDiv}>
            <div className={styles.reviewRateDiv}>
              {filledStar}
              <div>{`평점: ${d.score}/10`}</div>
            </div>
            <div className={styles.reviewContents}>{d.details}</div>
            <div className={styles.reviewDate}>{d.reviewed_date}</div>
          </div>
        ))
      }
      <BlankMaker height={30} />
      <ProductBottom
        productInfo={data}
        addHour={AddTime}
        leftDate={LeftDate}
        rightDate={RightDate}
      />
    </>
  );
};

export default Product;
