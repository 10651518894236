import axios from 'axios';
import React, { useState } from 'react';
import useSWR from 'swr';
import styles from '../Css/SelectDate.module.css';
import HorizontalLine from './HorizontalLine';
import IconAndText from './IconAndText';
import useCalendar from './useCalendar';

const useSelectDate = function (idx?: number|string) {
  const [LeftDate, setLeftDate] = useState<Date|null>(null);
  const [RightDate, setRightDate] = useState<Date|null>(null);
  const { calendarComponent, openCalendar } = useCalendar();
  const { data } = useSWR(`https://api.campingshare.co.kr/guest/unavailable/${idx}`, async (url: string) => {
    const res = await axios.get<string[]>(url);
    return res.data;
  });
  const excludeData = data?.map((str) => new Date(str)) || [];
  const calendar = (
    <svg viewBox="0 0 24 24" className={styles.icon}>
      <path fill="currentColor" d="M7,12H9V14H7V12M21,6V20A2,2 0 0,1 19,22H5C3.89,22 3,21.1 3,20V6A2,2 0 0,1 5,4H6V2H8V4H16V2H18V4H19A2,2 0 0,1 21,6M5,8H19V6H5V8M19,20V10H5V20H19M15,14V12H17V14H15M11,14V12H13V14H11M7,16H9V18H7V16M15,18V16H17V18H15M11,18V16H13V18H11Z" />
    </svg>
  );
  const getDateString = (date: Date) => `${date.getFullYear()}. ${date.getMonth() + 1}. ${date.getDate()}.`;
  return {
    SelectDate: (
      <div className={styles.dateBigDiv}>
        <IconAndText
          icon={calendar}
          boldText="대여일"
          lightText={LeftDate ? getDateString(LeftDate) : '캠핑카 대여 날짜를 선택해주세요'}
          onClick={() => openCalendar({
            dateCallback: setLeftDate,
            startDate: LeftDate,
            // maxDate: RightDate,
            exclude: excludeData,
          })}
        />
        <HorizontalLine marginTop={15} marginBottom={15} />
        <IconAndText
          icon={calendar}
          boldText="반납일"
          lightText={RightDate ? getDateString(RightDate) : '캠핑카 반납 날짜를 선택해주세요'}
          onClick={() => openCalendar({
            dateCallback: setRightDate,
            startDate: RightDate,
            // minDate: LeftDate,
            exclude: excludeData,
          })}
        />
        {calendarComponent}
      </div>
    ),
    LeftDate,
    setLeftDate,
    RightDate,
    setRightDate,
  };
};

export default useSelectDate;
