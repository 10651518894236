/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import EachTitle from '../../Components/EachTitle';
import LittleTitleAndText from '../../Components/LittleTitleAndText';
import MainTitle from '../../Components/MainTitle';
import NewCarCard from '../../Components/NewCarCard';
import useLogin from '../../Middle/useLogin';
import useLoginCheck from '../../Middle/useLoginCheck';
import styles from '../../Css/Reserved.module.css';
import BlankMaker from '../../Components/BlankMaker';
import { getStateInfo } from '../Admin/ReserveList';

export interface reservedI {
  'product_id': number,
  image: string,
  'state_return': number,
  'state_deposit': number,
  'start_date': string,
  'end_date': string,
  'orderer_phone': string,
  'orderer_details': string,
  'delivery_type': number,
  'delivery_details': string,
  'price': number,
  'add_hour': number,
  'night': number,
  'title': string,
  'contact': string,
  'location_details': string,
  'deliver_start': string,
  'deliver_end': string,
  'return_end': string,
  'admin_id': number,
  'admin_nickname': string,
  'license': string,
  'seats': number,
  'pet': number,
  'delivery': number,
  reviewavg: number
  reviewcnt: number
  reviewed: number
  payment_id: string
  equipmentList: {title: string, price: number, details: string}[]
  state_canceled: number
}

const Reserved = function () {
  useLoginCheck();
  const navigate = useNavigate();
  const { loginData } = useLogin();
  const [ShowReview, setShowReview] = useState('-1');
  const { data, mutate } = useSWR(loginData?.login && 'https://api.campingshare.co.kr/guest/reserved', async (url) => {
    if (!(loginData?.login)) return [];
    const res = await axios.get<reservedI[]>(url, loginData.axiosConfig);
    return res.data;
  });
  return (
    <>
      <MainTitle>예약 내역</MainTitle>
      {
        [...(data || [])].reverse().map((info) => (
          <NewCarCard
            info={{
              tags: [],
              like: -1,
              image: info.image,
              title: info.title,
              place: info.location_details,
              reviewRate: info.reviewavg,
              reviewPeople: info.reviewcnt,
              driver: info.license,
              people: info.seats,
              delivery: info.delivery === 1,
              pet: info.pet === 1,
              price: info.price,
              priceInfo: `${info.night}박${info.add_hour === 0 ? '' : ` + ${info.add_hour}시간`}`,
              onLike: () => {},
              discountInfo: { hasDiscount: false },
              state: getStateInfo(info.state_canceled, info.state_return).text,
              stateColor: getStateInfo(info.state_canceled, info.state_return).color,
            }}
          >
            <LittleTitleAndText title="대여 기간" content={`${info.start_date} ~ ${info.end_date}`} />
            <LittleTitleAndText title="인수 시간" content={`${info.deliver_start} ~ ${info.deliver_end} 까지`} />
            <LittleTitleAndText title="반납 시간" content={`${info.return_end} 까지`} />
            <button
              type="button"
              className={styles.reviewBtn}
              onClick={() => navigate(`./${info.payment_id}`)}
            >
              상세정보 보기
            </button>
            <BlankMaker height={10} />
            <div
              className={styles.reviewDiv}
            >
              {
                (info.state_return === 1
                  && info.reviewed === 0
                  && info.state_canceled === 0
                  && info.payment_id !== ShowReview) && (
                  <button
                    type="button"
                    className={styles.reviewBtn}
                    onClick={() => setShowReview(info.payment_id)}
                  >
                    후기작성
                  </button>
                )
              }
              {
                (info.state_return === 1
                  && info.reviewed === 0
                  && info.payment_id === ShowReview) && (
                  <>
                    <BlankMaker height={20} />
                    <form
                      action="submit"
                      className={styles.reviewForm}
                      onSubmit={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const lis = (e.target as unknown as {value: string}[]);
                        const rawNum = lis[0].value;
                        const content = lis[1].value.trim();
                        let value = 10;
                        try {
                          value = parseInt(rawNum, 10);
                        } catch {
                          alert('오류가 발생했습니다.');
                          return;
                        }
                        if (value < 0 || value > 10) {
                          alert('점수는 0점에서 10점 사이로 넣어주세요');
                          return;
                        }
                        if (content.length === 0) {
                          alert('후기란이 비어있습니다.');
                          return;
                        }
                        try {
                          await axios.post('https://api.campingshare.co.kr/guest/review/create', {
                            product_id: info.product_id,
                            payment_id: info.payment_id,
                            score: value,
                            details: content,
                          }, loginData?.login ? loginData.axiosConfig : {});
                          alert('등록했습니다.');
                          mutate();
                        } catch {
                          alert('오류가 발생했습니다.');
                        }
                      }}
                    >
                      <input type="number" placeholder="점수 (0 ~ 10)" defaultValue={10} />
                      <textarea placeholder="후기" />
                      <button
                        type="submit"
                        className={styles.reviewBtn}
                      >
                        등록하기
                      </button>
                    </form>
                  </>
                )
              }
            </div>
          </NewCarCard>
        ))
      }
      {
        (!data || data.length === 0) && <EachTitle marginTop={10}>예약 내역이 없습니다.</EachTitle>
      }
    </>
  );
};

export default Reserved;
