/* eslint-disable jsx-a11y/control-has-associated-label */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import styles from '../Css/Advertisement.module.css';

const emptyCircle = (
  <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
    <g><rect fill="none" height="24" width="24" /></g>
    <g><path d="M12,2C6.47,2,2,6.47,2,12c0,5.53,4.47,10,10,10s10-4.47,10-10C22,6.47,17.53,2,12,2z M12,20c-4.42,0-8-3.58-8-8 c0-4.42,3.58-8,8-8s8,3.58,8,8C20,16.42,16.42,20,12,20z" /></g>
  </svg>
);

const filledCircle = (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z" />
  </svg>
);

const rightArrow = (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
  </svg>
);

const Advertisement = function () {
  const { data } = useSWR('https://api.campingshare.co.kr/guest/notice', async (url: string) => {
    const res = await axios.get<{id:number, image:string, url:string}[]>(url);
    return res.data;
  });
  const [Now, setNow] = useState(0);
  const changeNum = (diff: number) => {
    if (!data) return;
    let newNum = Now + diff;
    if (newNum < 0) newNum += data.length;
    if (newNum >= data.length) newNum %= data.length;
    setNow(newNum);
  };
  useEffect(() => {
    changeNum(0);
  }, [data]);
  if (!data) return <div />;
  return (
    <div className={styles.topBannerDiv}>
      <div className={styles.topBannerInner} style={{ transform: `translateX(${Now * -100}%)` }}>
        {
          data.map((d) => (
            <a href={d.url}><img src={d.image} alt={`${d.id}`} /></a>
          ))
        }
      </div>
      <button type="button" onClick={() => changeNum(1)} className={`${styles.topBannerNext} ${styles.topBannerNextNext}`}>
        {rightArrow}
      </button>
      <button type="button" onClick={() => changeNum(-1)} className={`${styles.topBannerNext} ${styles.topBannerNextBefore}`}>
        {rightArrow}
      </button>
      <div className={styles.topBannerCircles}>
        {
          data.map((d, i) => (
            <button type="button" className={`${styles.topBannerCircle} ${i === Now && styles.selected}`} onClick={() => setNow(i)}>
              {
                i === Now ? filledCircle : emptyCircle
              }
            </button>
          ))
        }
      </div>
    </div>
  );
};

export default Advertisement;
