/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import MainTitle from '../../Components/MainTitle';
import newStyles from '../../Css/Option.module.css';
import styles from '../../Css/Price.module.css';
import sstyles from '../../Css/Schedule.module.css';
import infoStyles from '../../Css/Info.module.css';
import useAdminLogin from '../../Middle/useAdminLogin';
import { detailI } from './Price';
import iconInfo, { getIconURL, iconSpliter } from '../../Meta/icon';
import ContentMaker from '../../Components/ContentMaker';
import NewCarCard from '../../Components/NewCarCard';
import { regionList } from '../Customer/Main';

const Info = function () {
  const { id } = useParams();
  const { loginData } = useAdminLogin();
  const [Data, setData] = useState<detailI | null>(null);
  const fileInput = useRef<HTMLInputElement>(null);
  const { data: fetchData, mutate } = useSWR(id && loginData?.login && `https://api.campingshare.co.kr/admin/product/details/${id}`, async (url: string) => {
    const res = await axios.get<detailI>(url, loginData?.login ? loginData.axiosConfig : {});
    return res.data;
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  // const [MemSel, setMemSel] = useState<1|2|3>(1);
  useEffect(() => {
    if (fetchData) setData(fetchData);
  }, [fetchData]);
  const setNewData = (key: keyof detailI, value: any) => setData((beforeData: detailI | null) => {
    if (!beforeData) return beforeData;
    const newInfo = { ...beforeData };
    (newInfo[key] as any) = value;
    return newInfo;
  });

  if (!Data) return <div />;

  const onPush = async () => {
    try {
      await axios.post('https://api.campingshare.co.kr/admin/product/modify', Data, loginData?.login ? loginData.axiosConfig : {});
      alert('저장되었습니다.');
      mutate();
    } catch {
      alert('오류가 발생했습니다.');
    }
  };

  const optionDelete = (type: 'option'|'equipment', idx: number) => {
    const newInfo = { ...Data };
    (newInfo[type] as any) = [...newInfo[type].slice(0, idx), ...newInfo[type].slice(idx + 1)];
    setData(newInfo);
  };

  const optionChange = (idx: number, newText: string) => {
    const newOption = [...Data.option];
    newOption[idx] = { title: newText };
    setData({
      ...Data,
      option: newOption,
    });
  };

  const equipmentChamge = (idx: number, type: 'title'|'price', newVal: any) => {
    const newEquipment = [...Data.equipment];
    const newEach = { ...newEquipment[idx] };
    (newEach[type] as any) = newVal;
    newEquipment[idx] = newEach;
    setData({
      ...Data,
      equipment: newEquipment,
    });
  };

  const createOption = (v: string) => {
    setData({
      ...Data,
      option: [...Data.option, { title: v }],
    });
  };

  const createEquipment = () => {
    setData({
      ...Data,
      equipment: [...Data.equipment, { title: '새 장비', details: '', price: 0 }],
    });
  };

  return (
    <div className={styles.priceDiv}>
      <MainTitle>차량 설명 변경</MainTitle>
      {
        Data && (
        <NewCarCard info={{
          title: Data.title, tags: [], like: -1, image: Data.image, reviewRate: 9.9, reviewPeople: 999, place: Data.location_details, driver: Data.license, people: Data.seats, pet: Data.pet === 1, delivery: Data.delivery === 1, price: -1, priceInfo: '이렇게 보입니다', discountInfo: { hasDiscount: false }, onLike: () => {},
        }}
        />
        )
      }
      <div className={`${styles.innerPadding} ${styles.info}`}>
        <div className={styles.innerTitle}>캠핑카 이름</div>
        <input type="text" className={infoStyles.input} value={Data.title} onChange={(e) => setNewData('title', e.target.value)} />
        <div className={styles.innerTitle}>캠핑카 이미지 (상단에서 확인)</div>
        <input
          type="file"
          ref={fileInput}
          accept="image/*"
          style={{ display: 'none' }}
          onChange={async (e) => {
            if (!e.target.files) return;
            try {
              const formData = new FormData();
              formData.append('file', e.target.files[0]);
              const res = await axios.post<string>('https://api.campingshare.co.kr/admin/upload', formData, loginData?.login ? loginData.axiosConfig : {});
              setNewData('image', res.data);
              alert('이미지를 업로드했습니다.');
            } catch {
              alert('오류가 발생했습니다.');
            }
          }}
        />
        <button onClick={() => fileInput.current && fileInput.current.click()} className={sstyles.button} type="button">이미지 업로드하기</button>
        <div className={styles.innerTitle}>지역 분류</div>
        <select className={infoStyles.input} value={Data.location} onChange={(e) => setNewData('location', e.target.value)}>
          {
            regionList.slice(1).map((d, i) => (
              <option value={`${i + 1}`}>{d}</option>
            ))
          }
        </select>
        <div className={styles.innerTitle}>캠핑카 픽업 장소</div>
        <input type="text" className={infoStyles.input} value={Data.location_details} onChange={(e) => setNewData('location_details', e.target.value)} />
        <div className={styles.innerTitle}>인수 시간</div>
        <div className={styles.inputAndStringDiv}>
          <input type="time" value={Data.deliver_start} onChange={(e) => setNewData('deliver_start', e.target.value)} />
          <div>부터</div>
        </div>
        <div className={styles.inputAndStringDiv}>
          <input type="time" value={Data.deliver_end} onChange={(e) => setNewData('deliver_end', e.target.value)} />
          <div>까지</div>
        </div>
        <div className={styles.innerTitle}>반납 시간</div>
        <div className={styles.inputAndStringDiv}>
          <input type="time" value={Data.return_end} onChange={(e) => setNewData('return_end', e.target.value)} />
          <div>까지</div>
        </div>
        <div className={styles.innerTitle}>유료 딜리버리 가능 여부</div>
        <div className={`${styles.buttonsDiv} ${newStyles.buttonsDiv}`}>
          <button type="button" onClick={() => setNewData('delivery', 1)} className={`${Data.delivery === 1 && styles.selectedButton}`}>가능</button>
          <button type="button" onClick={() => setNewData('delivery', 0)} className={`${Data.delivery === 0 && styles.selectedButton}`}>불가능</button>
        </div>
        <div className={styles.innerTitle}>반려동물 동반 가능 여부</div>
        <div className={`${styles.buttonsDiv} ${newStyles.buttonsDiv}`}>
          <button type="button" onClick={() => setNewData('pet', 1)} className={`${Data.pet === 1 && styles.selectedButton}`}>가능</button>
          <button type="button" onClick={() => setNewData('pet', 0)} className={`${Data.pet === 0 && styles.selectedButton}`}>불가능</button>
        </div>
        <div className={styles.innerTitle}>좌석수</div>
        <div className={styles.inputAndStringDiv}>
          <input type="number" value={Data.seats} onChange={(e) => setNewData('seats', e.target.value)} />
          <div>석</div>
        </div>
        <div className={styles.innerTitle}>상품 설명</div>
        <ContentMaker data={Data.details} changeData={(e) => setNewData('details', e)} />
        <div className={styles.innerTitle}>딜리버리 가능 지역 안내</div>
        <textarea spellCheck={false} className={`${infoStyles.input} ${infoStyles.longInput}`} value={Data.delivery_warn} onChange={(e) => setNewData('delivery_warn', e.target.value)} />
        <div className={styles.innerTitle}>면허 종류</div>
        <input type="text" className={infoStyles.input} value={Data.license} onChange={(e) => setNewData('license', e.target.value)} />
        <div className={styles.innerTitle}>운전자 자격 관련 안내</div>
        <textarea spellCheck={false} className={`${infoStyles.input} ${infoStyles.longInput}`} value={Data.driver_warn} onChange={(e) => setNewData('driver_warn', e.target.value)} />
        <div className={styles.innerTitle}>환불 관련 규정 안내</div>
        <textarea spellCheck={false} className={`${infoStyles.input} ${infoStyles.longInput}`} value={Data.refund} onChange={(e) => setNewData('refund', e.target.value)} />
        <div className={styles.innerTitle}>자동차 보험 안내</div>
        <textarea spellCheck={false} className={`${infoStyles.input} ${infoStyles.longInput}`} value={Data.terms} onChange={(e) => setNewData('terms', e.target.value)} />
        <div className={styles.innerTitle}>옵션 설정</div>
        <div className={`${styles.optionList} ${infoStyles.optionList}`}>
          {
            Data.option.map((info, i) => {
              if (info.title.startsWith(iconSpliter)) {
                try {
                  const iconName = info.title.split(iconSpliter)[1];
                  return (
                    <div className={styles.inputAndStringDiv}>
                      <img src={getIconURL(iconName)} alt="" />
                      <input type="text" value={iconInfo[iconName]} className={infoStyles.input} placeholder="옵션명" disabled />
                      <div onClick={() => optionDelete('option', i)}>삭제하기</div>
                    </div>
                  );
                } catch {
                  // pass
                }
              }
              return (
                <div className={styles.inputAndStringDiv}>
                  <input type="text" value={Data.option[i].title} onChange={(e) => optionChange(i, e.target.value)} className={infoStyles.input} placeholder="옵션명" />
                  <div onClick={() => optionDelete('option', i)}>삭제하기</div>
                </div>
              );
            })
          }
        </div>
        <div className={styles.innerTitle}>기존 옵션 목록에서 추가</div>
        <div className={styles.optionLisDiv}>
          {
            Object.keys(iconInfo).map((key) => (
              <button type="button" onClick={() => createOption(`#csicon#${key}`)}>
                <img src={getIconURL(key)} alt={iconInfo[key]} />
                <div>{iconInfo[key]}</div>
              </button>
            ))
          }
        </div>
        <button onClick={() => createOption('새 옵션')} className={sstyles.button} type="button">새 옵션 만들기</button>

        <div className={styles.innerTitle}>장비 설명</div>
        <ContentMaker data={Data.equipment_details} changeData={(e) => setNewData('equipment_details', e)} />
        <div className={styles.innerTitle}>장비 설정</div>
        <div className={`${styles.optionList} ${infoStyles.optionList}`}>
          {
            Data.equipment.map((info, i) => (
              <div className={infoStyles.equipDiv}>
                <div>
                  <input type="text" value={Data.equipment[i].title} onChange={(e) => equipmentChamge(i, 'title', e.target.value)} className={infoStyles.input} placeholder="옵션명" />
                  <div className={styles.inputAndStringDiv}>
                    <input type="number" value={Data.equipment[i].price} onChange={(e) => equipmentChamge(i, 'price', e.target.value)} />
                    <div>원</div>
                  </div>
                </div>
                <div onClick={() => optionDelete('equipment', i)}>삭제하기</div>
              </div>
            ))
          }
        </div>
        <button onClick={createEquipment} className={sstyles.button} type="button">새 장비 만들기</button>
        <BlankMaker height={30} />
        <button onClick={onPush} className={sstyles.button} type="button">저장하기</button>
      </div>
    </div>
  );
};

export default Info;
