/* eslint-disable camelcase */
import axios from 'axios';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MainTitle from '../../Components/MainTitle';
import useLogin from '../../Middle/useLogin';

const LoginCallback = function () {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { loginMutate } = useLogin();
  const code = searchParams.get('code');
  useEffect(() => {
    (async () => {
      try {
        const params = new URLSearchParams();
        params.append('grant_type', 'authorization_code');
        params.append('client_id', 'ca5f7b5b53052ef2587ee7dd82498805');
        params.append('redirect_uri', `${location.origin}/logincallback`);
        params.append('code', `${code}`);
        const firstRes = await axios.post<{access_token: string}>('https://kauth.kakao.com/oauth/token', params);
        const { data } = firstRes;
        const { access_token } = data;
        const secondRes = await axios.post<{jwt: string}>('https://api.campingshare.co.kr/guest/kakao', { access_token });
        // console.log(secondRes.data.jwt);
        localStorage.setItem('jwt', secondRes.data.jwt);
        loginMutate();
        navigate('/');
      } catch {
        navigate('/');
      }
    })();
  }, []);

  if (!code) navigate('/');
  return (
    <div>
      <MainTitle>잠시만 기다려주세요</MainTitle>
    </div>
  );
};

export default LoginCallback;
