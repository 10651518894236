import React from 'react';
import { contentI } from './ContentMaker';
import styles from '../Css/ContentShow.module.css';

const sizeFont = {
  big: 20, medium: 16, small: 12,
};

const ContentShow = function (props: { data: string }) {
  let lis: contentI[] = [];
  const { data } = props;
  try {
    const tmp = JSON.parse(data);
    lis = tmp;
  } catch {
    lis = [{ type: 'text', content: data, subContent: { align: 'center', color: '#000000' } }];
  }
  return (
    <div className={styles.contentDiv}>
      {
        lis.map((d) => {
          if (d.type === 'text') {
            return (
              <div
                className={styles.text}
                style={{
                  textAlign: d.subContent.align,
                  color: d.subContent.color,
                  fontSize: `${d.subContent.size ? sizeFont[d.subContent.size] : 16}px`,
                }}
              >
                {d.content}
              </div>
            );
          }
          if (d.type === 'image') {
            return (
              <img src={d.content} alt="" />
            );
          }
          if (d.type === 'link') {
            return (
              <a href={d.content}>{d.subContent}</a>
            );
          }
          return <div />;
        })
      }
    </div>
  );
};

export default ContentShow;
