import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useAdminLogin from '../../Middle/useAdminLogin';
import styles from '../../Css/ChangePassword.module.css';
import MainTitle from '../../Components/MainTitle';
import EachTitle from '../../Components/EachTitle';

const NewAccount = function () {
  const navigate = useNavigate();
  const { loginData } = useAdminLogin();
  return (
    <div className={styles.mainDiv}>
      <MainTitle>새로운 관리자 계정 생성하기</MainTitle>
      <form
        action="submit"
        onSubmit={async (e) => {
          e.preventDefault();
          const name = (e.target as any)[0].value;
          const ID = (e.target as any)[1].value;
          const PW = (e.target as any)[2].value;
          const PWCheck = (e.target as any)[3].value;
          if (PW !== PWCheck) {
            alert('비밀번호 확인이 다릅니다');
            return;
          }
          try {
            const res = await axios.post('https://api.campingshare.co.kr/admin/signup', {
              login_id: ID,
              login_password: PW,
              location: 0,
              nickname: name,
            }, loginData?.login ? loginData.axiosConfig : {});
            alert('생성했습니다.');
            navigate('/account');
          } catch {
            alert('오류가 발생했습니다.');
          }
        }}
      >
        <input type="text" placeholder="업체명" />
        <input type="text" placeholder="아이디" />
        <input type="password" placeholder="비밀번호" />
        <input type="password" placeholder="비밀번호 확인" />
        <button type="submit">생성하기</button>
      </form>
    </div>
  );
};

export default NewAccount;
