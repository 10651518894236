import axios from 'axios';
import React from 'react';
import useSWR from 'swr';
import MainTitle from '../../Components/MainTitle';
import NewCarCard from '../../Components/NewCarCard';
// import styles from '../../Css/CustomerLikeList.module.css';
import useLogin from '../../Middle/useLogin';
import useLoginCheck from '../../Middle/useLoginCheck';
import { searchCarInfoI } from './Main';

const LikeList = function () {
  // const dummyInfo = {
  //   company: '캠핑쉐어',
  //   name: '캠핑카, 코반마스터 690',
  //   imageUrl: carImg,
  //   place: '서울특별시 종로구 청와대로 1',
  //   tags: [
  //     '22시간 / 1박',
  //     '5인승',
  //     '딜리버리 가능',
  //     '애견동반 가능',
  //     '연박 할인',
  //     '최소 5박',
  //   ],
  //   nowPrice: 350000,
  //   andSlash: '/ 1박',
  //   liked: true,
  // };
  // const dummyDiscount = {
  //   hasDiscount: true,
  //   beforePrice: 400000,
  //   discountPercent: 13,
  //   discountName: '그린슈머 할인가',
  // };
  const { loginData } = useLogin();
  useLoginCheck();
  const { data } = useSWR<searchCarInfoI[]>('https://api.campingshare.co.kr/guest/liked', async (url) => {
    const res = await axios.get(url, loginData?.login ? loginData.axiosConfig : {});
    return res.data;
  });
  return (
    <>
      <MainTitle>찜 목록</MainTitle>
      {
        data?.map((eachData) => {
          const hasDiscount = eachData.discountPercentage > 0;
          return (
            <NewCarCard info={{
              tags: eachData.feature,
              like: eachData.like ? 1 : 0,
              onLike: () => {},
              image: eachData.image,
              title: eachData.title,
              link: `/product/${eachData.id}`,
              reviewRate: eachData.reviewavg,
              reviewPeople: eachData.reviewcnt,
              place: eachData.location_details,
              driver: eachData.license,
              people: eachData.seats,
              pet: eachData.pet === 1,
              delivery: eachData.delivery === 1,
              price: eachData.price,
              priceInfo: '1박',
              discountInfo: hasDiscount
                ? {
                  hasDiscount: true,
                  beforePrice: eachData.originPrice,
                  discountRate: eachData.discountPercentage,
                  discountName: eachData.discount,
                }
                : {
                  hasDiscount: false,
                },
            }}
            />
          );
        })
      }
    </>
  );
};

export default LikeList;
