/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ReactElement } from 'react';
import styles from '../Css/IconAndText.module.css';

const IconAndText = function (props:{
  icon:ReactElement, boldText:string, lightText: string, onClick?: () => void}) {
  const {
    icon, boldText, lightText, onClick,
  } = props;
  return (
    <div className={styles.dateLittleDiv} onClick={onClick}>
      <div className={styles.iconDiv}>
        {icon}
      </div>
      <div className={styles.dateTextsDiv}>
        <div className={styles.dateTextBold}>{boldText}</div>
        <div className={styles.dateTextLight}>{lightText}</div>
      </div>
    </div>
  );
};

export default IconAndText;
