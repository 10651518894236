import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../Css/MasterMain.module.css';

const MasterMain = function () {
  return (
    <div className={styles.mainDiv}>
      <div className={styles.titleDiv}>마스터 관리자 페이지</div>
      <Link to="/account"><div>업체별 관리자 계정 관리</div></Link>
      <Link to="/notice"><div>메인화면 공지사항 관리</div></Link>
      <Link to="/customer"><div>회원 관리</div></Link>
      <Link to="/logout"><div>로그아웃</div></Link>
    </div>
  );
};

export default MasterMain;
