import axios, { AxiosRequestConfig } from 'axios';
import useSWR from 'swr';

interface userInfo {
  nickname: string,
  email: string,
  membership: number
}

export function useLogin() {
  const { data, mutate, isValidating } = useSWR<{login: false} | {login: true, info: userInfo, axiosConfig: AxiosRequestConfig}>('login', async () => {
    const accessToken = localStorage.getItem('jwt');
    if (accessToken) {
      try {
        const res = await axios.post<userInfo>('https://api.campingshare.co.kr/guest/kakao', {}, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const { data: resData } = res;
        if (resData) {
          return {
            login: true,
            info: resData,
            axiosConfig: {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
          };
        }
        return {
          login: false,
        };
      } catch {
        return {
          login: false,
        };
      }
    }
    return {
      login: false,
    };
  });
  return {
    loginData: data,
    loginMutate: mutate,
    loginIsValidating: isValidating,
  };
}

export default useLogin;
