import axios from 'axios';
import React from 'react';
import styles from '../Css/ContentMaker.module.css';
import infoStyles from '../Css/Info.module.css';
import useLogin from '../Middle/useLogin';

export interface etcContentI {
  type: 'image'|'link'
  content: string
  subContent: string
}

export interface textContentI {
  type: 'text'
  content: string
  subContent: {
    align: 'left' | 'center' | 'right'
    color: string
    size?: 'big' | 'medium' | 'small'
  }
}

export type contentI = etcContentI | textContentI

// eslint-disable-next-line no-unused-vars
const ContentMaker = function (props: { data: string, changeData: (n: string) => void}) {
  const { loginData } = useLogin();
  const { data, changeData } = props;
  let contentList: contentI[] = [];
  const change = (i: number, key: keyof contentI, value: any) => {
    contentList[i][key] = value as unknown as any;
    changeData(JSON.stringify(contentList));
  };
  const changeType = (i: number, type: string) => {
    if (type === 'text') {
      contentList[i].type = type;
      contentList[i].content = '내용';
      contentList[i].subContent = {
        align: 'center',
        color: '#000000',
      };
    } else if (type === 'link') {
      contentList[i].type = type;
      contentList[i].content = 'https://www.naver.com';
      contentList[i].subContent = '링크 이름';
    } else if (type === 'image') {
      contentList[i].type = type;
      contentList[i].content = 'https://campingshare-bucket-image.s3.ap-northeast-2.amazonaws.com/1645162028898_camp.png';
      contentList[i].subContent = '';
    }
    changeData(JSON.stringify(contentList));
  };
  const deleteData = (idx: number) => {
    contentList = [...contentList.slice(0, idx), ...contentList.slice(idx + 1)];
    changeData(JSON.stringify(contentList));
  };
  const changeOrder = (idx: number, diff: -1|1) => {
    if (idx === 0 && diff === -1) return;
    if (idx === contentList.length - 1 && diff === 1) return;
    const newNewData = [...contentList];
    newNewData[idx] = contentList[idx + diff];
    newNewData[idx + diff] = contentList[idx];
    contentList = newNewData;
    changeData(JSON.stringify(contentList));
  };
  try {
    const tmp = JSON.parse(data);
    contentList = (tmp as contentI[]).map((d) => {
      if (d.type === 'text' && typeof d.subContent === 'string') {
        return {
          type: d.type, content: d.content, subContent: { align: 'center', color: '#000000' },
        };
      }
      return d;
    });
  } catch {
    contentList = [{ type: 'text', content: data, subContent: { align: 'center', color: '#000000' } }];
  }
  return (
    <div className={styles.lisDiv}>
      {
        contentList.map((d, i) => {
          let innerContent = <div />;
          if (d.type === 'text') {
            innerContent = (
              <div className={styles.linkDiv}>
                <textarea spellCheck={false} className={`${infoStyles.input} ${styles.longInput}`} value={d.content} onChange={(e) => change(i, 'content', e.target.value)} />
                <div className={styles.texts}>
                  <div className={styles.lisTypeDiv}>
                    <div>색</div>
                    <input type="color" value={d.subContent.color} onChange={(e) => change(i, 'subContent', { ...d.subContent, color: e.target.value })} />
                  </div>
                  <div className={styles.lisTypeDiv}>
                    <div>정렬</div>
                    <select
                      value={d.subContent.align}
                      onChange={(e) => change(i, 'subContent', { ...d.subContent, align: e.target.value })}
                    >
                      <option value="left">왼쪽</option>
                      <option value="center">가운데</option>
                      <option value="right">오른쪽</option>
                    </select>
                  </div>
                  <div className={styles.lisTypeDiv}>
                    <div>크기</div>
                    <select
                      value={d.subContent.size || 'medium'}
                      onChange={(e) => change(i, 'subContent', { ...d.subContent, size: e.target.value })}
                    >
                      <option value="big">크게</option>
                      <option value="medium">중간</option>
                      <option value="small">작게</option>
                    </select>
                  </div>
                </div>
              </div>
            );
          } else if (d.type === 'link') {
            innerContent = (
              <div className={styles.linkDiv}>
                <input type="url" value={d.content} className={`${infoStyles.input}`} placeholder="링크" onChange={(e) => change(i, 'content', e.target.value)} />
                <input type="text" value={d.subContent} className={`${infoStyles.input}`} placeholder="보여질 텍스트" onChange={(e) => change(i, 'subContent', e.target.value)} />
              </div>
            );
          } else {
            innerContent = (
              <div className={styles.linkDiv}>
                <img src={d.content} alt="" />
                <form
                  action="submit"
                  onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    (e.target as unknown as any[])[0].click();
                  }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={async (e) => {
                      if (!e.target.files) return;
                      try {
                        const formData = new FormData();
                        formData.append('file', e.target.files[0]);
                        const res = await axios.post<string>('https://api.campingshare.co.kr/admin/upload', formData, loginData?.login ? loginData.axiosConfig : {});
                        change(i, 'content', res.data);
                        alert('이미지를 업로드했습니다.');
                      } catch {
                        alert('오류가 발생했습니다.');
                      }
                    }}
                  />
                  <button type="submit">이미지 업로드하기</button>

                </form>

              </div>
            );
          }
          return (
            <div className={styles.lisEachDiv}>
              <div className={styles.lisTypeDiv}>
                <div>타입</div>
                <select value={d.type} onChange={(e) => changeType(i, e.target.value)}>
                  <option value="text">텍스트</option>
                  <option value="image">이미지</option>
                  <option value="link">링크</option>
                </select>
              </div>
              {innerContent}
              <div className={styles.buttons}>
                <button type="button" onClick={() => changeOrder(i, -1)}>⬆️</button>
                <button type="button" onClick={() => changeOrder(i, 1)}>⬇️</button>
                <button type="button" onClick={() => deleteData(i)}>삭제</button>
              </div>
            </div>
          );
        })
      }
      <button
        type="button"
        onClick={() => {
          contentList = [...contentList, { type: 'text', content: '내용', subContent: { align: 'center', color: '#000000' } }];
          changeData(JSON.stringify(contentList));
        }}
      >
        추가
      </button>
    </div>
  );
};

export default ContentMaker;
