import React, { ReactElement } from 'react';
import styles from '../Css/EachTitle.module.css';

const EachTitle = function (props:{
  children:ReactElement|string, marginTop?:number, alignCenter?: boolean}) {
  const { children, marginTop, alignCenter } = props;
  return (
    <div className={styles.titleDiv} style={{ marginTop, textAlign: alignCenter ? 'center' : 'match-parent' }}>
      {children}
    </div>
  );
};

EachTitle.defaultProps = {
  marginTop: 35,
  alignCenter: false,
};

export default EachTitle;
