import React from 'react';
import { Link } from 'react-router-dom';
import MainTitle from '../../Components/MainTitle';
import styles from '../../Css/MyInfo.module.css';
import useLogin from '../../Middle/useLogin';
import useLoginCheck from '../../Middle/useLoginCheck';

const membershipToString: {[x:number]: string} = {
  1: '일반',
  2: '그린슈머',
  3: '여행사',
};

const MyInfo = function () {
  const { loginData } = useLogin();
  useLoginCheck();
  if (!loginData?.login) return <div />;
  return (
    <div className={styles.myInfoDiv}>
      <MainTitle>내 정보</MainTitle>
      <div className={styles.myInfoInner}>
        <div className={styles.nameAndMembershipDiv}>
          <div>{loginData.info.nickname}</div>
          <div>{`${membershipToString[loginData.info.membership]}회원`}</div>
        </div>
        <div className={styles.emailDiv}>{loginData.info.email}</div>
      </div>
      <div className={styles.linksDiv}>
        <Link to="/reserved">
          <div>예약내역</div>
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
          </svg>
        </Link>
        <Link to="/logout">
          <div>로그아웃</div>
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default MyInfo;
