import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { productI } from '../Pages/Customer/Product';
import styles from '../Css/ProductBottom.module.css';
import CountMover from './CountMover';
import TwoTable from './TwoTable';
import useLogin from '../Middle/useLogin';

const ProductBottom = function (props: {
  addHour: number,
  productInfo: productI,
  leftDate: Date|null,
  rightDate: Date|null
}) {
  const {
    productInfo, addHour, leftDate, rightDate,
  } = props;
  // const { id } = useParams();
  const { loginData } = useLogin();
  const [Detail, setDetail] = useState(false);
  const [NumberList, setNumberList] = useState(productInfo.equipment.map(() => 0));
  const navigate = useNavigate();
  const setNumber = (newNum: number, idx: number) => {
    const newList = [...NumberList];
    newList[idx] = newNum;
    setNumberList(newList);
  };
  const defaultPrice = productInfo.product.price + (addHour * productInfo.product.add_hour_price);
  const price = defaultPrice + NumberList.map(
    (num, i) => num * productInfo.equipment[i].price,
  ).reduce((a, b) => a + b, 0);
  return (
    <>
      <button className={`${styles.button} ${Detail ? styles.buttonDetail : ''}`} type="button" onClick={() => { setDetail((dt) => !dt); }}>{Detail ? '닫기' : '자세히 보기'}</button>
      <div className={`${styles.navBarDiv} ${Detail ? styles.detail : ''}`}>
        <div className={styles.topDiv}>
          <div className={styles.topInner}>
            <TwoTable
              leftBold
              rowGap={5}
              leftList={productInfo.equipment.map((d) => d.title)}
              rightList={productInfo.equipment.map((d, i) => (
                <>
                  {`${d.price.toLocaleString('ko-KR')}원 `}
                  <CountMover Number={NumberList[i]} setNumber={(num) => setNumber(num, i)} />
                </>
              ))}
            />
          </div>
        </div>
        <div className={styles.bottomDiv}>
          <div className={styles.priceDiv}>
            <div className={styles.priceTop}>총 대여료(VAT 포함)</div>
            <div className={styles.priceBottom}>{productInfo.product.available ? `${price.toLocaleString('ko-KR')}원` : '예약이 불가능한 일정입니다.'}</div>
          </div>
          <button
            onClick={() => {
              if (productInfo.product.available && loginData?.login && leftDate && rightDate) {
                navigate('/reserve', {
                  state: {
                    addHour, productInfo, NumberList, leftDate, rightDate, price,
                  },
                });
              } else if (!loginData?.login) alert('로그인이 필요합니다.');
            }}
            type="button"
            className={styles.reserveBtn}
          >
            <div>예약하기</div>
          </button>
        </div>
      </div>
    </>
  );
};

export default ProductBottom;
