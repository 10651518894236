/* eslint-disable react/destructuring-assignment */
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styles from '../Css/NewCarCard.module.css';
import licenseIcon from '../Meta/license.png';
import peopleIcon from '../Meta/people.png';
import petIcon from '../Meta/pet.png';
import deliveryIcon from '../Meta/delivery.png';
import HorizontalLine from './HorizontalLine';

export interface carCardInfoI {
  tags: string[],
  like: -1 | 0 | 1,
  image: string,
  title: string,
  link?: string | undefined | null,
  reviewRate: number,
  reviewPeople: number,
  place: string,
  driver: string,
  people: number,
  pet: boolean,
  delivery: boolean,
  price: number,
  priceInfo: string,
  discountInfo: {
    hasDiscount: false
  } | {
    hasDiscount: true,
    beforePrice: number,
    discountRate: number | string,
    discountName: string
  }
  onLike: null | undefined | (() => void)
  state?: string
  stateColor?: string
}

const emptyLike = (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z" />
  </svg>
);

const filledLike = (
  <svg viewBox="0 0 24 24" fill="#FF3636">
    <path d="M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z" />
  </svg>
);

export const filledStar = (
  <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
    <g>
      <rect fill="none" height="24" width="24" x="0" />
      <polygon points="14.43,10 12,2 9.57,10 2,10 8.18,14.41 5.83,22 12,17.31 18.18,22 15.83,14.41 22,10" />
    </g>
  </svg>
);

const placeIcon = (
  <svg viewBox="0 0 24 24">
    <path d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
  </svg>
);

const NewCarCard = function (props: {
   info: carCardInfoI, children?: ReactNode | ReactNode[] | null | undefined }) {
  const { info, children } = props;
  const inner = (
    <div className={styles.inner}>
      <div className={styles.imgsDiv}>
        <img src={info.image} alt={info.title} />
        <div className={styles.imgInfoDiv}>
          <div className={styles.tagsDiv}>
            {
              info.tags.map((d) => <div>{d}</div>)
            }
          </div>
          {
            info.like !== -1 && (
              <button
                type="button"
                className={styles.likeBtn}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (info.onLike) {
                    (info.onLike)();
                  }
                }}
              >
                {
                  info.like === 0 ? emptyLike : filledLike
                }
              </button>
            )
          }
        </div>
      </div>
      <div className={styles.infoDiv}>
        {
          info.state && (
            <div className={styles.state} style={{ color: info.stateColor || 'black', borderBottom: `1px solid ${info.stateColor || 'black'}` }}>{info.state}</div>
          )
        }
        <div className={styles.title}>{info.title}</div>
        {
          info.reviewPeople > 0 && (
            <div className={styles.reviewDiv}>
              {filledStar}
              <div className={styles.reviewRate}>{`평점: ${info.reviewRate.toFixed(1)}`}</div>
              <div className={styles.reviewPeople}>{`(${info.reviewPeople}명)`}</div>
            </div>
          )
        }
        <div className={styles.placeDiv}>
          {placeIcon}
          {`차고지: ${info.place}`}
        </div>
        <div className={styles.iconInfoDiv}>
          {
            info.driver.length > 0 && (
              <div className={styles.eachIconInfo}>
                <img src={licenseIcon} alt="운전면허" />
                <div>{info.driver}</div>
              </div>
            )
          }
          {
            info.people > 0 && (
              <div className={styles.eachIconInfo}>
                <img src={peopleIcon} alt="탑승가능인원" />
                <div>{`${info.people}인승`}</div>
              </div>
            )
          }
          {
            info.delivery && (
              <div className={styles.eachIconInfo}>
                <img src={deliveryIcon} alt="딜리버리 가능" />
                <div>딜리버리 가능</div>
              </div>
            )
          }
          {
            info.pet && (
              <div className={styles.eachIconInfo}>
                <img src={petIcon} alt="반려동물 동반 가능" />
                <div>반려동물 동반 가능</div>
              </div>
            )
          }
        </div>
        {
          info.price >= 0 && (
            <>
              <HorizontalLine marginTop={10} marginBottom={10} />
              <div className={styles.priceDiv}>
                <div className={styles.nowPriceDiv}>
                  <div className={styles.nowPrice}>{`${info.price.toLocaleString()}원`}</div>
                  <div className={styles.nowPriceInfo}>{`(${info.priceInfo})`}</div>
                </div>
                {
                  info.discountInfo.hasDiscount && (
                    <div className={styles.discountDiv}>
                      <div className={styles.discountPriceDiv}>
                        <div className={styles.discountPrice}>{`${info.discountInfo.beforePrice.toLocaleString()}원`}</div>
                        <div className={styles.discountRate}>{`${info.discountInfo.discountRate}% 할인`}</div>
                      </div>
                      <div className={styles.discountInfo}>
                        {info.discountInfo.discountName}
                      </div>
                    </div>
                  )
                }
              </div>
            </>
          )
        }
      </div>
      {
        children && (
          <>
            <HorizontalLine marginTop={0} marginBottom={0} />
            <div className={styles.childrenDiv}>
              {children}
            </div>
          </>
        )
      }
    </div>
  );
  return (
    info.link
      ? (<Link to={info.link} className={styles.bigDiv}>{inner}</Link>)
      : (<div className={styles.bigDiv}>{inner}</div>)
  );
};

export default NewCarCard;
