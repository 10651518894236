import React, { useState } from 'react';
import styles from '../Css/CountMover.module.css';

const CountMover = function (props: {
  Number: number,
  setNumber: (num: number) => void
}) {
  const { Number, setNumber } = props;
  const adder = (number:number) => {
    if (number < 99) return number + 1;
    return number;
  };
  const miner = (number:number) => {
    if (number > 0) return number - 1;
    return number;
  };
  return (
    <div className={styles.countMoverDiv}>
      <button type="button" onClick={() => { setNumber(miner(Number)); }}>-</button>
      <div><div>{Number}</div></div>
      <button type="button" onClick={() => { setNumber(adder(Number)); }}>+</button>
    </div>
  );
};

export default CountMover;
