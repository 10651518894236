import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import MainTitle from '../../Components/MainTitle';
import styles from '../../Css/Notice.module.css';
import useAdminLogin from '../../Middle/useAdminLogin';

const EachNotice = function (props: {data: {image: string, url: string}, index: number, functions: {
  changeData: any, deleteData: any, changeOrder: any
}}) {
  const { loginData } = useAdminLogin();
  const { data, index, functions } = props;
  const { changeData, deleteData, changeOrder } = functions;
  const fileRef = useRef<HTMLInputElement>(null);
  return (
    <div className={styles.eachNoticeDiv}>
      <img src={data.image} alt="이미지" />
      <input type="text" value={data.url} placeholder="연결될 URL" onChange={(e) => changeData('url', index, e.target.value)} />
      <input
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={async (e) => {
          if (!e.target.files) return;
          try {
            const formData = new FormData();
            formData.append('file', e.target.files[0]);
            const res = await axios.post<string>('https://api.campingshare.co.kr/admin/upload', formData, loginData?.login ? loginData.axiosConfig : {});
            changeData('image', index, res.data);
            alert('이미지를 변경했습니다.');
          } catch {
            alert('오류가 발생했습니다.');
          }
        }}
        ref={fileRef}
      />
      <div>
        <button type="button" onClick={() => changeOrder(index, -1)}>⬆️ 위로</button>
        <button type="button" onClick={() => changeOrder(index, 1)}>⬇️ 아래로</button>
      </div>
      <button type="button" onClick={() => fileRef.current?.click()}>이미지 변경하기</button>
      <button type="button" onClick={() => deleteData(index)}>삭제하기</button>
    </div>
  );
};

const Notice = function () {
  const { loginData } = useAdminLogin();
  const [NewData, setNewData] = useState<{image: string, url: string}[]>([]);
  const { data, mutate } = useSWR('https://api.campingshare.co.kr/admin/getnotice', async (url) => {
    const res = await axios.get<{
      id: number, image: string, url: string
    }[]>(url, loginData?.login ? loginData.axiosConfig : {});
    return res.data.map((d) => ({ image: d.image, url: d.url }));
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  useEffect(() => {
    if (data) {
      setNewData(data);
    }
  }, [data]);
  const deleteData = (idx: number) => {
    setNewData([...NewData.slice(0, idx), ...NewData.slice(idx + 1)]);
  };
  const makeData = (isFirst: boolean) => {
    if (isFirst) {
      setNewData([{
        image: 'https://campingshare-bucket-image.s3.ap-northeast-2.amazonaws.com/1645162028898_camp.png',
        url: 'https://www.naver.com/',
      }, ...NewData]);
    } else {
      setNewData([...NewData, {
        image: 'https://campingshare-bucket-image.s3.ap-northeast-2.amazonaws.com/1645162028898_camp.png',
        url: 'https://www.naver.com/',
      }]);
    }
  };
  const changeData = (type: 'image'|'url', idx: number, newValue: string) => {
    const newEach = { ...NewData[idx] };
    newEach[type] = newValue;
    const newNewData = [...NewData];
    newNewData[idx] = newEach;
    setNewData(newNewData);
  };
  const changeOrder = (idx: number, change: -1|1) => {
    if (idx === 0 && change === -1) return;
    if (idx === NewData.length - 1 && change === 1) return;
    const newNewData = [...NewData];
    newNewData[idx] = NewData[idx + change];
    newNewData[idx + change] = NewData[idx];
    setNewData(newNewData);
  };

  return (
    <div className={styles.mainDiv}>
      <MainTitle>공지사항 변경</MainTitle>
      <div className={styles.noticeList}>
        <button type="button" onClick={() => makeData(true)}>새로운 공지사항 추가(맨 처음)</button>
        {
          NewData.map((d, i) => (
            <EachNotice data={d} index={i} functions={{ changeData, changeOrder, deleteData }} />
          ))
        }
        <button type="button" onClick={() => makeData(false)}>새로운 공지사항 추가(맨 끝)</button>
      </div>
      <button
        type="button"
        onClick={async () => {
          try {
            const res = await axios.post('https://api.campingshare.co.kr/admin/modifynotice', { notice: NewData }, loginData?.login ? loginData.axiosConfig : {});
            alert('저장했습니다.');
            mutate();
          } catch {
            alert('오류가 발생했습니다.');
          }
        }}
      >
        저장하기

      </button>
    </div>
  );
};

export default Notice;
