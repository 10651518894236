import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import Logout from './Logout';
import Option from './Option';
import Price from './Price';
import ProductList from './ProductList';
import ReserveList from './ReserveList';
import Schedule from './Schedule';
import styles from '../../Css/IAMAdmin.module.css';
import Info from './Info';
import Review from './Review';

const IAMAdmin = function () {
  return (
    <>
      <div className={styles.headerDiv}>
        <Link to="/">예약내역</Link>
        <Link to="/product">차량목록</Link>
        <Link to="/logout">로그아웃</Link>
      </div>
      <Routes>
        <Route path="/" element={<ReserveList />} />
        <Route path="/product" element={<ProductList />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/price/:id" element={<Price />} />
        <Route path="/review/:id" element={<Review />} />
        <Route path="/option/:id" element={<Option />} />
        <Route path="/info/:id" element={<Info />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </>
  );
};

export default IAMAdmin;
