/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import MainTitle from '../../Components/MainTitle';
import NewCarCard from '../../Components/NewCarCard';
import newStyles from '../../Css/Option.module.css';
import styles from '../../Css/Price.module.css';
import sstyles from '../../Css/Schedule.module.css';
import useAdminLogin from '../../Middle/useAdminLogin';
import { detailI } from './Price';

const Option = function () {
  const { id } = useParams();
  const { loginData } = useAdminLogin();
  const [Data, setData] = useState<detailI | null>(null);
  const { data: fetchData, mutate } = useSWR(id && loginData?.login && `https://api.campingshare.co.kr/admin/product/details/${id}`, async (url: string) => {
    const res = await axios.get<detailI>(url, loginData?.login ? loginData.axiosConfig : {});
    return res.data;
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (fetchData) setData(fetchData);
  }, [fetchData]);
  const setNewData = (key: keyof detailI, value: any) => setData((beforeData: detailI | null) => {
    if (!beforeData) return beforeData;
    const newInfo = { ...beforeData };
    (newInfo[key] as any) = value;
    return newInfo;
  });

  if (!Data) return <div />;

  const onPush = async () => {
    try {
      await axios.post('https://api.campingshare.co.kr/admin/product/modify', Data, loginData?.login ? loginData.axiosConfig : {});
      alert('저장되었습니다.');
      mutate();
    } catch {
      alert('오류가 발생했습니다.');
    }
  };

  return (
    <div className={styles.priceDiv}>
      <MainTitle>차량별 옵션 설정</MainTitle>
      {
        Data && (
          <NewCarCard info={{
            title: Data.title, tags: [], like: -1, image: Data.image, reviewRate: 9.9, reviewPeople: 999, place: Data.location_details, driver: Data.license, people: Data.seats, pet: Data.pet === 1, delivery: Data.delivery === 1, price: -1, priceInfo: '이렇게 보입니다', discountInfo: { hasDiscount: false }, onLike: () => {},
          }}
          />
        )
      }
      <div className={styles.innerPadding}>
        <div className={styles.innerTitle}>부가세 (VAT)</div>
        <div className={`${styles.buttonsDiv} ${newStyles.buttonsDiv}`}>
          <button type="button" onClick={() => setNewData('include_vat', 0)} className={`${Data.include_vat === 0 && styles.selectedButton}`}>별도</button>
          <button type="button" onClick={() => setNewData('include_vat', 1)} className={`${Data.include_vat === 1 && styles.selectedButton}`}>포함</button>
        </div>
        <BlankMaker height={10} />
        <div className={styles.innerTitle}>성수기 기간</div>
        <div className={newStyles.twoDateDiv}>
          <input type="date" value={Data.peak_start} onChange={(e) => setNewData('peak_start', e.target.value)} />
          <div>~</div>
          <input type="date" value={Data.peak_end} onChange={(e) => setNewData('peak_end', e.target.value)} />
        </div>
        <BlankMaker height={10} />
        <div className={styles.innerTitle}>금요일-토요일 사용 주말 가격 여부</div>
        <div className={`${styles.buttonsDiv} ${newStyles.buttonsDiv}`}>
          <button type="button" onClick={() => setNewData('fri_as_weekend', 0)} className={`${Data.fri_as_weekend === 0 && styles.selectedButton}`}>주중</button>
          <button type="button" onClick={() => setNewData('fri_as_weekend', 1)} className={`${Data.fri_as_weekend === 1 && styles.selectedButton}`}>주말</button>
        </div>
        <BlankMaker height={10} />
        <div className={styles.innerTitle}>일요일-월요일 사용 주말 가격 여부</div>
        <div className={`${styles.buttonsDiv} ${newStyles.buttonsDiv}`}>
          <button type="button" onClick={() => setNewData('sun_as_weekend', 0)} className={`${Data.sun_as_weekend === 0 && styles.selectedButton}`}>주중</button>
          <button type="button" onClick={() => setNewData('sun_as_weekend', 1)} className={`${Data.sun_as_weekend === 1 && styles.selectedButton}`}>주말</button>
        </div>
        <BlankMaker height={10} />
        <div className={styles.innerTitle}>상품 공개 여부</div>
        <div className={`${styles.buttonsDiv} ${newStyles.buttonsDiv}`}>
          <button type="button" onClick={() => setNewData('visible', 0)} className={`${Data.visible === 0 && styles.selectedButton}`}>비공개</button>
          <button type="button" onClick={() => setNewData('visible', 1)} className={`${Data.visible === 1 && styles.selectedButton}`}>공개</button>
        </div>
        <BlankMaker height={10} />
        <div className={styles.innerTitle}>최소 박수 설정</div>
        <div className={styles.innerTitle}>비수기</div>
        <div className={styles.leftAndRightDiv}>
          <div>주중</div>
          <div className={styles.inputAndStringDiv}>
            <input type="number" value={Data.min_day_off_weekday} onChange={(e) => setNewData('min_day_off_weekday', e.target.value)} />
            <div>박</div>
          </div>
        </div>
        <div className={styles.leftAndRightDiv}>
          <div>주말</div>
          <div className={styles.inputAndStringDiv}>
            <input type="number" value={Data.min_day_off_weekend} onChange={(e) => setNewData('min_day_off_weekend', e.target.value)} />
            <div>박</div>
          </div>
        </div>
        <BlankMaker height={10} />
        <div className={styles.innerTitle}>성수기</div>
        <div className={styles.leftAndRightDiv}>
          <div>주중</div>
          <div className={styles.inputAndStringDiv}>
            <input type="number" value={Data.min_day_peak_weekday} onChange={(e) => setNewData('min_day_peak_weekday', e.target.value)} />
            <div>박</div>
          </div>
        </div>
        <div className={styles.leftAndRightDiv}>
          <div>주말</div>
          <div className={styles.inputAndStringDiv}>
            <input type="number" value={Data.min_day_peak_weekend} onChange={(e) => setNewData('min_day_peak_weekend', e.target.value)} />
            <div>박</div>
          </div>
        </div>
      </div>
      <BlankMaker height={30} />
      <button onClick={onPush} className={sstyles.button} type="button">저장하기</button>
    </div>
  );
};

export default Option;
