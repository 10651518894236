import axios from 'axios';
import React from 'react';
import useSWR from 'swr';
import { Link } from 'react-router-dom';
import MainTitle from '../../Components/MainTitle';
import useAdminLogin from '../../Middle/useAdminLogin';
import styles from '../../Css/AdminAccount.module.css';
import EachTitle from '../../Components/EachTitle';

const AdminAccount = function () {
  const { loginData } = useAdminLogin();
  const { data } = useSWR('https://api.campingshare.co.kr/admin/admin/list', async (url: string) => {
    const res = await axios.get<{
      admin_id: string, admin_nickname: string, region: number
    }[]>(url, loginData?.login ? loginData.axiosConfig : {});
    return res.data;
  });
  return (
    <div className={styles.adminAccountDiv}>
      <MainTitle>업체별 관리자 계정 목록</MainTitle>
      <EachTitle marginTop={0} alignCenter>업체명 (ID) / 클릭하여 비밀번호 변경</EachTitle>
      <div className={styles.accountList}>
        {
          data?.map((d) => (
            <Link to="/changepassword" state={{ login_id: d.admin_id, nickname: d.admin_nickname }}><div>{`${d.admin_nickname} (${d.admin_id})`}</div></Link>
          ))
        }
        <Link to="/newaccount"><div className={styles.new}>새로운 관리자 계정 만들기</div></Link>
      </div>
    </div>
  );
};

export default AdminAccount;
