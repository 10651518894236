import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminAccount from './AdminAccount';
import ChangePassword from './ChangePassword';
import CustomerList from './CustomerList';
import Logout from './Logout';
import MasterMain from './MasterMain';
import NewAccount from './NewAccount';
import Notice from './Notice';

const Master = function () {
  return (
    <Routes>
      <Route path="/" element={<MasterMain />} />
      <Route path="/account" element={<AdminAccount />} />
      <Route path="/changepassword" element={<ChangePassword />} />
      <Route path="/newaccount" element={<NewAccount />} />
      <Route path="/notice" element={<Notice />} />
      <Route path="/customer" element={<CustomerList />} />
      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
};

export default Master;
