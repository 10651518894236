import React, { ReactNode } from 'react';
import styles from '../Css/LittleTitleAndText.module.css';

const LittleTitleAndText = function (props: { title: string, content: ReactNode}) {
  const { title, content } = props;
  return (
    <div className={styles.bigDiv}>
      <div className={styles.titleDiv}>{title}</div>
      <div className={styles.contentDiv}>{content}</div>
    </div>
  );
};

export default LittleTitleAndText;
