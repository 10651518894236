import axios from 'axios';
import React from 'react';
import useSWR from 'swr';
import MainTitle from '../../Components/MainTitle';
import useAdminLogin from '../../Middle/useAdminLogin';
import styles from '../../Css/CustomerList.module.css';
import pstyles from '../../Css/Price.module.css';

const memString = ['', '일반', '그린슈머', '여행사'];

const CustomerList = function () {
  const { loginData } = useAdminLogin();
  const { data, mutate } = useSWR('https://api.campingshare.co.kr/admin/customer/list', async (url) => {
    const res = await axios.get<{
      email: string, nickname: string, membership: number, point: number
    }[]>(url, loginData?.login ? loginData.axiosConfig : {});
    return res.data;
  });
  const changeMembership = async (idx: number, newVal: number) => {
    if (!data) return;
    const d = data[idx];
    if (!d) return;
    if (data[idx].membership === newVal) return;
    if (!confirm(`${d.nickname} (${d.email}) 님의 멤버십을 [${memString[d.membership]} -> ${memString[newVal]}] 변경하시겠습니까?`)) return;
    try {
      await axios.post('https://api.campingshare.co.kr/admin/customer/modify', {
        email: d.email,
        after_nickname: d.nickname,
        after_point: d.point,
        after_membership: newVal,
      }, loginData?.login ? loginData.axiosConfig : {});
      alert('변경했습니다.');
      mutate();
    } catch {
      alert('오류가 발생했습니다.');
    }
  };
  return (
    <div className={styles.mainDiv}>
      <MainTitle>회원 목록</MainTitle>
      <div className={styles.listDiv}>
        {
          data?.map((d, i) => (
            <div className={styles.eachLis}>
              <div>{d.nickname}</div>
              <div>{d.email}</div>
              <div className={pstyles.buttonsDiv}>
                <button type="button" onClick={() => changeMembership(i, 1)} className={`${d.membership === 1 && pstyles.selectedButton}`}>일반</button>
                <button type="button" onClick={() => changeMembership(i, 2)} className={`${d.membership === 2 && pstyles.selectedButton}`}>그린슈머</button>
                <button type="button" onClick={() => changeMembership(i, 3)} className={`${d.membership === 3 && pstyles.selectedButton}`}>여행사</button>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default CustomerList;
