/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ko } from 'date-fns/locale';
import React, { useState } from 'react';
import ReactDatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import styles from '../Css/useCalendar.module.css';

const arrowSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000">
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M15.61 7.41L14.2 6l-6 6 6 6 1.41-1.41L11.03 12l4.58-4.59z" />
  </svg>
);

export default function useInlineCalendar(startDate: Date|null, clickable: boolean) {
  const [DateObj, setDateObj] = useState<Date | null>(startDate);
  const [YearMonth, setYearMonth] = useState<
  [number, number]>([(new Date()).getFullYear(), (new Date()).getMonth() + 1]);
  const changeMonth = (diff: number) => {
    let year = YearMonth[0];
    let month = YearMonth[1];
    month += diff;
    if (month < 1) {
      month += 12;
      year -= 1;
    } else if (month > 12) {
      month -= 12;
      year += 1;
    }
    setYearMonth([year, month]);
    setDateObj(new Date(year, month - 1, 1));
  };
  const renderCustomHeader = (props: ReactDatePickerCustomHeaderProps) => {
    const {
      monthDate,
      decreaseMonth, increaseMonth,
      prevMonthButtonDisabled, nextMonthButtonDisabled,
    } = props;
    const tmp = (
      <div className={styles.monthPickerDiv}>
        <button type="button" className={`${prevMonthButtonDisabled && styles.btnDisable}`} onClick={() => { if (prevMonthButtonDisabled) return; decreaseMonth(); changeMonth(-1); }}>{arrowSvg}</button>
        <div className={styles.monthPickerText}>{`${monthDate.getFullYear()}년 ${monthDate.getMonth() + 1}월`}</div>
        <button type="button" className={`${nextMonthButtonDisabled && styles.btnDisable}`} onClick={() => { if (nextMonthButtonDisabled) return; increaseMonth(); changeMonth(1); }}>{arrowSvg}</button>
      </div>
    );
    return <div />;
  };

  const renderDayContents = (dayOfMonth: number, dateDay: Date|undefined, lis: number[]) => {
    if (!dateDay) return <div />;
    const isHighlight = lis.indexOf(dayOfMonth) !== -1;
    const isNo = dateDay.getMonth() + 1 !== YearMonth[1];
    const isToday = dateDay.getDate() === DateObj?.getDate()
    && dateDay.getMonth() === DateObj?.getMonth()
    && dateDay.getFullYear() === DateObj?.getFullYear();
    return (
      <div onClick={(e) => { e.stopPropagation(); if (isHighlight) setDateObj(dateDay); }} className={`${styles.calenDayDiv}`} style={{ background: 'white' }}>
        <div className={`${isHighlight && styles.highlightDay} ${isNo && styles.noDay} ${isToday && clickable && styles.todayDay}`}>{dateDay.getDate()}</div>
      </div>
    );
  };

  const Calendar = function (props: { lis: number[] }) {
    const { lis } = props;
    return (
      <div className={styles.calendarDivNew}>
        <div className={styles.calendarButtonDiv}>
          <button type="button" onClick={() => changeMonth(-1)}>{arrowSvg}</button>
          <div>{`${YearMonth[0]}년 ${YearMonth[1]}월`}</div>
          <button type="button" onClick={() => changeMonth(1)}>{arrowSvg}</button>
        </div>

        <ReactDatePicker
          selected={DateObj}
          onChange={(dt) => {}}
          locale={ko}
          inline
          renderCustomHeader={renderCustomHeader}
          renderDayContents={(a, b) => renderDayContents(a, b, lis)}
          disabledKeyboardNavigation
        />
      </div>
    );
  };
  return {
    Calendar,
    DateObj,
    YearMonth,
  };
}
