import React, { ReactChild, ReactNode } from 'react';
import styles from '../Css/TwoTable.module.css';

const TwoTable = function (props: {
  leftList: ReactChild[],
  rightList: ReactChild[],
  leftBold?: boolean,
  rightBold?: boolean,
  marginTop?: number,
  marginBottom?: number,
  fontSize?: number,
  rowGap?: number,
}) {
  const {
    leftList, rightList, leftBold, rightBold, marginTop, marginBottom, fontSize, rowGap,
  } = props;
  return (
    <div
      className={`${styles.tableDiv} ${leftBold ? styles.leftBold : ''} ${rightBold ? styles.rightBold : ''}`}
      style={{
        marginTop, marginBottom, fontSize, rowGap,
      }}
    >
      {
        leftList.length < rightList.length
          ? leftList.map((leftItem, idx) => (
            <div className={styles.tableLine}>
              <div className={styles.left}>{leftItem}</div>
              <div className={styles.right}>{rightList[idx]}</div>
            </div>
          ))
          : rightList.map((rightItem, idx) => (
            <div className={styles.tableLine}>
              <div className={styles.left}>{leftList[idx]}</div>
              <div className={styles.right}>{rightItem}</div>
            </div>
          ))
      }
    </div>
  );
};

TwoTable.defaultProps = {
  leftBold: false,
  rightBold: false,
  marginTop: 0,
  marginBottom: 0,
  fontSize: 14,
  rowGap: 3,
};

export default TwoTable;
