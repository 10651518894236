import axios from 'axios';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import useSWR from 'swr';
import LittleTitleAndText from '../../Components/LittleTitleAndText';
import MainTitle from '../../Components/MainTitle';
import NewCarCard from '../../Components/NewCarCard';
import useLoginCheck from '../../Middle/useLoginCheck';
import { getStateInfo } from '../Admin/ReserveList';

export interface reservedI {
  'product_id': number,
  image: string,
  'state_return': number,
  'state_deposit': number,
  'start_date': string,
  'end_date': string,
  'orderer_phone': string,
  'orderer_details': string,
  'delivery_type': number,
  'delivery_details': string,
  'price': number,
  'add_hour': number,
  'night': number,
  'title': string,
  'contact': string,
  'location_details': string,
  'deliver_start': string,
  'deliver_end': string,
  'return_end': string,
  'admin_id': number,
  'admin_nickname': string
  license: string
  seats: number
  pet: number,
  delivery: number
  equipments: {title: string, price: number, details: string}[]
  state_canceled: number
}

const Finish = function (props: { isFinish: boolean }) {
  const { isFinish } = props;
  useLoginCheck();
  const { id } = useParams();
  const { data: info } = useSWR(`https://api.campingshare.co.kr/guest/reserve/${id}`, async (url) => {
    const res = await axios.get<reservedI>(url);
    return res.data;
  });
  if (!info) return <div />;
  return (
    <>
      <MainTitle>{isFinish ? '예약이 완료되었습니다' : '예약 상세 정보'}</MainTitle>
      <NewCarCard
        info={{
          tags: [],
          like: -1,
          image: info.image,
          title: info.title,
          place: info.location_details,
          reviewRate: 0,
          reviewPeople: -1,
          driver: info.license,
          people: info.seats,
          delivery: info.delivery === 1,
          pet: info.pet === 1,
          price: info.price,
          priceInfo: `${info.night}박${info.add_hour === 0 ? '' : ` + ${info.add_hour}시간`}`,
          onLike: () => {},
          discountInfo: { hasDiscount: false },
          state: getStateInfo(info.state_canceled, info.state_return).text,
          stateColor: getStateInfo(info.state_canceled, info.state_return).color,
        }}
      >
        <LittleTitleAndText title="대여 기간" content={`${info.start_date} ~ ${info.end_date}`} />
        <LittleTitleAndText title="인수 시간" content={`${info.deliver_start} ~ ${info.deliver_end} 까지`} />
        <LittleTitleAndText title="반납 시간" content={`${info.return_end} 까지`} />

        <LittleTitleAndText title="예약자 정보" content={`${info.orderer_details}`} />
        <LittleTitleAndText title="예약자 전화번호" content={`${info.orderer_phone}`} />
        <LittleTitleAndText title="딜리버리 / 픽업 정보" content={`${info.delivery_details}`} />
        <LittleTitleAndText title="추가 장비 정보" content={`${info.equipments.length > 0 ? info.equipments.map((d) => d.title).join(', ') : '없음'}`} />
      </NewCarCard>
      <Link
        to={isFinish ? '/' : '/reserved'}
        style={{
          width: '200px',
          height: '50px',
          textAlign: 'center',
          background: '#188E72',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '50px',
          borderRadius: '25px',
        }}
      >
        {isFinish ? '메인화면으로 가기' : '예약 내역으로 가기'}
      </Link>
    </>
  );
};

export default Finish;
