/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import EachTitle from '../../Components/EachTitle';
import HorizontalLine from '../../Components/HorizontalLine';
import MainTitle from '../../Components/MainTitle';
import NewCarCard from '../../Components/NewCarCard';
import styles from '../../Css/Price.module.css';
import sstyles from '../../Css/Schedule.module.css';
import infoStyles from '../../Css/Info.module.css';
import newStyles from '../../Css/Option.module.css';
import useAdminLogin from '../../Middle/useAdminLogin';

export interface detailI {
  'id': number,
  'add_hour_price_off_weekday': number,
  'add_hour_price_off_weekend': number,
  'add_hour_price_peak_weekday': number,
  'add_hour_price_peak_weekend': number,
  'concatenation_discount_2n_type': number,
  'concatenation_discount_2n_price': number,
  'concatenation_discount_3n_type': number,
  'concatenation_discount_3n_price': number,
  'concatenation_discount_4n_type': number,
  'concatenation_discount_4n_price': number,
  'concatenation_discount_5n_type': number,
  'concatenation_discount_5n_price': number,
  'contact': string,
  'deliver_start': string,
  'deliver_end': string,
  'return_end': string,
  'delivery': number,
  'details': string,
  'hour_per_day': number,
  'include_vat': number,
  'location': number,
  'location_details': string,
  'min_day_off_weekday': number,
  'min_day_off_weekend': number,
  'min_day_peak_weekday': number,
  'min_day_peak_weekend': number,
  'min_hour_add': number,
  'peak_start': string,
  'peak_end': string,
  'price_mem1_off_weekday': number,
  'price_mem1_off_weekend': number,
  'price_mem1_peak_weekday': number,
  'price_mem1_peak_weekend': number,
  'price_mem2_off_weekday': number,
  'price_mem2_off_weekend': number,
  'price_mem2_peak_weekday': number,
  'price_mem2_peak_weekend': number,
  'price_mem3_off_weekday': number,
  'price_mem3_off_weekend': number,
  'price_mem3_peak_weekday': number,
  'price_mem3_peak_weekend': number,
  'seats': number,
  'sun_as_weekend': number,
  'visible': number,
  'admin_id': number,
  'title': string,
  'image': string,
  'delivery_warn': string,
  'driver_warn': string,
  'createdAt': null,
  'updatedAt': null,
  license: string,
  pet: number,
  equipment_details: string,
  terms: string,
  refund: string,
  max_hour_add: number
  fri_as_weekend: number,
  'event': {
    concatenation_discount: number
    event_start: string
    event_end: string
    discount_percentage: number
    title: string
  }[],
  equipment: {
    title: string,
    price: number,
    details: string
  }[],
  option: {
    title: string
  }[]
}

const Price = function () {
  const { id } = useParams();
  const { loginData } = useAdminLogin();
  const [Data, setData] = useState<detailI | null>(null);
  const { data: fetchData, mutate } = useSWR(id && loginData?.login && `https://api.campingshare.co.kr/admin/product/details/${id}`, async (url: string) => {
    const res = await axios.get<detailI>(url, loginData?.login ? loginData.axiosConfig : {});
    return res.data;
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const [MemSel, setMemSel] = useState<1|2|3>(1);
  useEffect(() => {
    if (fetchData) setData(fetchData);
  }, [fetchData]);
  const setNewData = (key: keyof detailI, value: any) => setData((beforeData: detailI | null) => {
    if (!beforeData) return beforeData;
    const newInfo = { ...beforeData };
    (newInfo[key] as any) = value;
    return newInfo;
  });
  const eventChange = (i: number, key: keyof detailI['event'][0], value: any) => {
    if (!Data) return;
    const newEvents = [...Data.event];
    const newEvent = { ...newEvents[i] };
    (newEvent as any)[key as unknown as any] = value;
    newEvents[i] = newEvent;
    setNewData('event', newEvents);
  };

  if (!Data) return <div />;

  const onPush = async () => {
    try {
      await axios.post('https://api.campingshare.co.kr/admin/product/modify', Data, loginData?.login ? loginData.axiosConfig : {});
      alert('저장되었습니다.');
      mutate();
    } catch {
      alert('오류가 발생했습니다.');
    }
  };

  const newEvent = () => {
    const newEvents: detailI['event'] = [...Data.event, {
      title: '새 특가 할인',
      event_start: '2022-05-05',
      event_end: '2022-05-05',
      concatenation_discount: 1,
      discount_percentage: 0,
    }];
    setNewData('event', newEvents);
  };

  const deleteEvent = (i: number) => {
    const newEvents = [...Data.event.slice(0, i), ...Data.event.slice(i + 1)];
    setNewData('event', newEvents);
  };

  return (
    <div className={styles.priceDiv}>
      <MainTitle>차량별 가격 설정</MainTitle>
      {
        Data && (
          <NewCarCard info={{
            title: Data.title, tags: [], like: -1, image: Data.image, reviewRate: 9.9, reviewPeople: 999, place: Data.location_details, driver: Data.license, people: Data.seats, pet: Data.pet === 1, delivery: Data.delivery === 1, price: -1, priceInfo: '이렇게 보입니다', discountInfo: { hasDiscount: false }, onLike: () => {},
          }}
          />
        )
      }
      <MainTitle>1박 기준 시간</MainTitle>
      <div className={styles.inputAndStringDiv}>
        <input type="number" value={Data?.hour_per_day} onChange={(e) => setNewData('hour_per_day', e.target.value)} />
        <div>시간</div>
      </div>
      <HorizontalLine marginTop={23} marginBottom={0} />
      <MainTitle>가격 설정</MainTitle>
      <div className={styles.buttonsDiv}>
        <button type="button" onClick={() => setMemSel(1)} className={`${MemSel === 1 && styles.selectedButton}`}>일반</button>
        <button type="button" onClick={() => setMemSel(2)} className={`${MemSel === 2 && styles.selectedButton}`}>그린슈머</button>
        <button type="button" onClick={() => setMemSel(3)} className={`${MemSel === 3 && styles.selectedButton}`}>여행사</button>
      </div>
      <BlankMaker height={20} />
      <div className={styles.innerPadding}>
        <div className={styles.innerTitle}>비수기</div>
        <div className={styles.leftAndRightDiv}>
          <div>주중 / 1박</div>
          <div className={styles.inputAndStringDiv}>
            <input type="number" value={Data[`price_mem${MemSel}_off_weekday`]} onChange={(e) => setNewData(`price_mem${MemSel}_off_weekday`, e.target.value)} />
            <div>원</div>
          </div>
        </div>
        <div className={styles.leftAndRightDiv}>
          <div>주말 / 1박</div>
          <div className={styles.inputAndStringDiv}>
            <input type="number" value={Data[`price_mem${MemSel}_off_weekend`]} onChange={(e) => setNewData(`price_mem${MemSel}_off_weekend`, e.target.value)} />
            <div>원</div>
          </div>
        </div>
        <BlankMaker height={10} />
        <div className={styles.innerTitle}>성수기</div>
        <div className={styles.leftAndRightDiv}>
          <div>주중 / 1박</div>
          <div className={styles.inputAndStringDiv}>
            <input type="number" value={Data[`price_mem${MemSel}_peak_weekday`]} onChange={(e) => setNewData(`price_mem${MemSel}_peak_weekday`, e.target.value)} />
            <div>원</div>
          </div>
        </div>
        <div className={styles.leftAndRightDiv}>
          <div>주말 / 1박</div>
          <div className={styles.inputAndStringDiv}>
            <input type="number" value={Data[`price_mem${MemSel}_peak_weekend`]} onChange={(e) => setNewData(`price_mem${MemSel}_peak_weekend`, e.target.value)} />
            <div>원</div>
          </div>
        </div>
      </div>
      <HorizontalLine marginTop={23} marginBottom={0} />
      <MainTitle>시간 추가 단위</MainTitle>
      <div className={styles.inputAndStringDiv}>
        <input type="number" value={Data.min_hour_add} onChange={(e) => setNewData('min_hour_add', e.target.value)} />
        <div>시간</div>
      </div>
      <MainTitle>최대 추가 시간</MainTitle>
      <div className={styles.inputAndStringDiv}>
        <input type="number" value={Data.max_hour_add} onChange={(e) => setNewData('max_hour_add', e.target.value)} />
        <div>시간</div>
      </div>
      <MainTitle>시간 추가 가격</MainTitle>
      <div className={styles.innerPadding}>
        <div className={styles.innerTitle}>비수기</div>
        <div className={styles.leftAndRightDiv}>
          <div>주중 / 1시간</div>
          <div className={styles.inputAndStringDiv}>
            <input type="number" value={Data.add_hour_price_off_weekday} onChange={(e) => setNewData('add_hour_price_off_weekday', e.target.value)} />
            <div>원</div>
          </div>
        </div>
        <div className={styles.leftAndRightDiv}>
          <div>주말 / 1시간</div>
          <div className={styles.inputAndStringDiv}>
            <input type="number" value={Data.add_hour_price_off_weekend} onChange={(e) => setNewData('add_hour_price_off_weekend', e.target.value)} />
            <div>원</div>
          </div>
        </div>
        <BlankMaker height={10} />
        <div className={styles.innerTitle}>성수기</div>
        <div className={styles.leftAndRightDiv}>
          <div>주중 / 1시간</div>
          <div className={styles.inputAndStringDiv}>
            <input type="number" value={Data.add_hour_price_peak_weekday} onChange={(e) => setNewData('add_hour_price_peak_weekday', e.target.value)} />
            <div>원</div>
          </div>
        </div>
        <div className={styles.leftAndRightDiv}>
          <div>주말 / 1시간</div>
          <div className={styles.inputAndStringDiv}>
            <input type="number" value={Data.add_hour_price_peak_weekend} onChange={(e) => setNewData('add_hour_price_peak_weekend', e.target.value)} />
            <div>원</div>
          </div>
        </div>
      </div>
      <HorizontalLine marginTop={23} marginBottom={0} />
      <MainTitle>연박 할인 설정</MainTitle>
      <div className={styles.innerPadding}>
        <EachTitle marginTop={0}>원/%를 클릭하여 서로 전환 가능</EachTitle>
        <div className={styles.leftAndRightDiv}>
          <div>2박</div>
          <div className={styles.inputAndStringDiv}>
            <input type="number" value={Data.concatenation_discount_2n_price} onChange={(e) => setNewData('concatenation_discount_2n_price', e.target.value)} />
            <div onClick={() => setNewData('concatenation_discount_2n_type', Data.concatenation_discount_2n_type === 0 ? 1 : 0)}>{Data.concatenation_discount_2n_type === 0 ? '%' : '원'}</div>
          </div>
        </div>
        <div className={styles.leftAndRightDiv}>
          <div>3박</div>
          <div className={styles.inputAndStringDiv}>
            <input type="number" value={Data.concatenation_discount_3n_price} onChange={(e) => setNewData('concatenation_discount_3n_price', e.target.value)} />
            <div onClick={() => setNewData('concatenation_discount_3n_type', Data.concatenation_discount_3n_type === 0 ? 1 : 0)}>{Data.concatenation_discount_3n_type === 0 ? '%' : '원'}</div>
          </div>
        </div>
        <div className={styles.leftAndRightDiv}>
          <div>4박</div>
          <div className={styles.inputAndStringDiv}>
            <input type="number" value={Data.concatenation_discount_4n_price} onChange={(e) => setNewData('concatenation_discount_4n_price', e.target.value)} />
            <div onClick={() => setNewData('concatenation_discount_4n_type', Data.concatenation_discount_4n_type === 0 ? 1 : 0)}>{Data.concatenation_discount_4n_type === 0 ? '%' : '원'}</div>
          </div>
        </div>
        <div className={styles.leftAndRightDiv}>
          <div>5박 이상</div>
          <div className={styles.inputAndStringDiv}>
            <input type="number" value={Data.concatenation_discount_5n_price} onChange={(e) => setNewData('concatenation_discount_5n_price', e.target.value)} />
            <div onClick={() => setNewData('concatenation_discount_5n_type', Data.concatenation_discount_5n_type === 0 ? 1 : 0)}>{Data.concatenation_discount_5n_type === 0 ? '%' : '원'}</div>
          </div>
        </div>
      </div>
      <HorizontalLine marginTop={23} marginBottom={0} />
      <MainTitle>특가 할인 설정</MainTitle>
      {
        Data.event.map((d, i) => (
          <div className={styles.special}>
            <div className={styles.innerTitle}>특가명</div>
            <BlankMaker height={10} />
            <input type="text" className={infoStyles.input} value={d.title} onChange={(e) => eventChange(i, 'title', e.target.value)} />
            <BlankMaker height={20} />
            <div className={styles.innerTitle}>할인 기간</div>
            <BlankMaker height={10} />
            <div className={newStyles.twoDateDiv}>
              <input type="date" value={d.event_start} onChange={(e) => eventChange(i, 'event_start', e.target.value)} />
              <div>~</div>
              <input type="date" value={d.event_end} onChange={(e) => eventChange(i, 'event_end', e.target.value)} />
            </div>
            <BlankMaker height={20} />
            <div className={styles.innerTitle}>연박할인과 중복 적용 여부</div>
            <BlankMaker height={10} />
            <div className={`${styles.buttonsDiv} ${newStyles.buttonsDiv}`}>
              <button type="button" onClick={() => eventChange(i, 'concatenation_discount', 1)} className={`${d.concatenation_discount === 1 && styles.selectedButton}`}>적용</button>
              <button type="button" onClick={() => eventChange(i, 'concatenation_discount', 0)} className={`${d.concatenation_discount === 0 && styles.selectedButton}`}>미적용</button>
            </div>
            <BlankMaker height={20} />
            <div className={styles.innerTitle}>할인율</div>
            <BlankMaker height={10} />
            <div className={styles.inputAndStringDiv}>
              <input type="number" value={d.discount_percentage} onChange={(e) => eventChange(i, 'discount_percentage', parseInt(e.target.value, 10))} />
              <div>%</div>
            </div>
            <button onClick={() => deleteEvent(i)} className={sstyles.button} type="button">삭제</button>
          </div>
        ))
      }
      <button onClick={newEvent} className={sstyles.button} type="button">새 특가 할인 만들기</button>
      <BlankMaker height={30} />
      <button onClick={onPush} className={sstyles.button} type="button">저장하기</button>
    </div>
  );
};

export default Price;
