import React from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import styles from '../Css/Header.module.css';
import logoGreen from '../Meta/logoGreen.png';
import menuIcon from '../Meta/menuIcon.png';
import phoneIcon from '../Meta/phoneIcon.png';
import kakaoTalkIcon from '../Meta/kakaoTalkIcon.png';
import HorizontalLine from './HorizontalLine';
import useMenu from './useMenu';
import useLogin from '../Middle/useLogin';

const Header = function (props:{now?:'main'|'myinfo'|'likelist'|'reserved'}) {
  const { now } = props;
  const { Open, setOpen, MenuComponent } = useMenu();
  const { loginData } = useLogin();
  return (
    <div className={styles.headerDiv}>
      <a href="/"><img src={logoGreen} alt="logo" className={styles.logoImg} /></a>
      <div className={styles.menuDiv}>
        <a href="tel:0216612840"><img src={phoneIcon} alt="munu" className={styles.iconImg} /></a>
        <a href="http://pf.kakao.com/_xePQpK/chat"><img src={kakaoTalkIcon} alt="munu" className={styles.iconImg} /></a>
        <button type="button" onClick={() => setOpen(!Open)}>
          <img src={menuIcon} alt="munu" className={styles.menuImg} />
        </button>
      </div>
      <MenuComponent>
        <div className={styles.modalDiv}>
          <div className={styles.headerModalDiv}>
            {
              loginData?.login
                ? <div>{`${loginData.info.nickname} 님`}</div>
                : <div>로그인하세요.</div>
            }
            <button type="button" onClick={() => setOpen(false)}>
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>
          <div className={styles.headerModalDiv}>
            <Link to={loginData?.login ? '/logout' : '/login'} className={styles.modalBtnDiv} onClick={() => setOpen(false)}>{loginData?.login ? '로그아웃' : '로그인'}</Link>
            <Link to={loginData?.login ? '/myinfo' : '/signup'} className={styles.modalBtnDiv} onClick={() => setOpen(false)}>{loginData?.login ? '내 정보' : '회원가입'}</Link>
          </div>
          <div className={styles.bottomBlank} />
          <div className={styles.navBarDiv}>
            <Link to="/" className={`${styles.iconAndText} ${now === 'main' ? styles.now : ''}`} onClick={() => setOpen(false)}>
              <div className={styles.textDiv}>홈</div>
            </Link>
            <HorizontalLine marginTop={0} marginBottom={0} />
            <Link to="/likelist" className={`${styles.iconAndText} ${now === 'likelist' ? styles.now : ''}`} onClick={() => setOpen(false)}>
              <div className={styles.textDiv}>찜목록</div>
            </Link>
            <HorizontalLine marginTop={0} marginBottom={0} />
            <Link to="/reserved" className={`${styles.iconAndText} ${now === 'reserved' ? styles.now : ''}`} onClick={() => setOpen(false)}>
              <div className={styles.textDiv}>예약내역</div>
            </Link>
            <HorizontalLine marginTop={0} marginBottom={0} />
            <Link to="/myinfo" className={`${styles.iconAndText} ${now === 'myinfo' ? styles.now : ''}`} onClick={() => setOpen(false)}>
              <div className={styles.textDiv}>내 정보</div>
            </Link>
          </div>
        </div>
      </MenuComponent>
    </div>
  );
};

export default Header;
