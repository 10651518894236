/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import axios from 'axios';
import Advertisement from '../../Components/Advertisement';
import EachTitle from '../../Components/EachTitle';
import IconAndText from '../../Components/IconAndText';
import useSelectDate from '../../Components/useSelectDate';
import HorizontalLine from '../../Components/HorizontalLine';
import styles from '../../Css/CustomerMain.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import useLogin from '../../Middle/useLogin';
import getDateDiff from '../../Middle/getDateDiff';
import getDateString from '../../Middle/getDateString';
import useModal from '../../Components/useModal';
import MainTitle from '../../Components/MainTitle';
import useLike from '../../Middle/useLike';
import NewCarCard from '../../Components/NewCarCard';

export interface searchCarInfoI {
  'price': number,
  'id': number,
  image: string,
  'title': string,
  'contact': string,
  'deliver_start': string,
  'deliver_end': string,
  'return_end': string,
  'delivery': number,
  'location': number,
  'location_details': string,
  'like': boolean,
  'feature': string[],
  'adminNickname': string,
  'originPrice': number,
  'add_hour_price': number,
  'discountPercentage': number,
  'discount': string,
  'license': string,
  'seats': number,
  'pet': number,
  reviewavg: number,
  reviewcnt: number,
}

export const regionList = [
  '전체 지역', '서울', '경기', '제주', '인천', '부산', '대구', '광주', '대전', '세종', '울산', '경남', '경북', '전남', '전북',
];

const Main = function () {
  // const dummyInfo = {
  //   company: '캠핑쉐어',
  //   name: '캠핑카, 코반마스터 690',
  //   imageUrl: carImg,
  //   place: '서울특별시 종로구 청와대로 1',
  //   tags: [
  //     '22시간 / 1박',
  //     '5인승',
  //     '딜리버리 가능',
  //     '애견동반 가능',
  //     '연박 할인',
  //     '최소 5박',
  //   ],
  //   nowPrice: 350000,
  //   andSlash: '/ 1박',
  //   liked: false,
  // };
  // const dummyDiscount = {
  //   hasDiscount: true,
  //   beforePrice: 400000,
  //   discountPercent: 13,
  //   discountName: '그린슈머 할인가',
  // };
  const { loginData } = useLogin();
  const doLike = useLike();
  const { SelectDate, LeftDate, RightDate } = useSelectDate();
  const [Region, setRegion] = useState(0);
  const { data, mutate } = useSWR<searchCarInfoI[]>(`https://api.campingshare.co.kr/guest/search?location=${Region}${LeftDate ? `&start=${getDateString(LeftDate)}` : ''}${RightDate ? `&end=${getDateString(RightDate)}` : ''}`, async (url) => {
    const res = await axios.get(url, loginData?.login ? loginData.axiosConfig : {});
    return res.data;
  });
  useEffect(() => {
    mutate();
  }, [loginData]);
  const { setOpen, ModalComponent } = useModal();

  return (
    <>
      <Advertisement />
      <EachTitle>캠핑카 찾기</EachTitle>
      <IconAndText
        icon={(
          <svg viewBox="0 0 24 24">
            <path fill="currentColor" d="M12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5M12,2A7,7 0 0,1 19,9C19,14.25 12,22 12,22C12,22 5,14.25 5,9A7,7 0 0,1 12,2M12,4A5,5 0 0,0 7,9C7,10 7,12 12,18.71C17,12 17,10 17,9A5,5 0 0,0 12,4Z" />
          </svg>
        )}
        boldText="대여 장소"
        lightText={Region === 0 ? '캠핑카 픽업 위치를 선택해주세요' : regionList[Region]}
        onClick={() => setOpen(true)}
      />
      <ModalComponent>
        <div className={styles.regionModal}>
          <MainTitle>픽업 위치</MainTitle>
          <div className={styles.listDiv}>
            {
              regionList.map((text, i) => (
                <div
                  onClick={() => {
                    setRegion(i);
                    setOpen(false);
                  }}
                  className={`${i === 0 && styles.wide}`}
                >
                  {}
                  {text}
                </div>
              ))
            }
          </div>
          <div className={styles.modalCloseDiv} onClick={() => setOpen(false)}>취소</div>
        </div>
      </ModalComponent>
      <HorizontalLine marginTop={17} marginBottom={17} />
      {SelectDate}
      <HorizontalLine marginTop={13} marginBottom={0} />
      <EachTitle>캠핑카 목록</EachTitle>
      {/* <NewCarCard info={{
        tags: ['20시간/1박', '연박할인', '애견동반 가능'],
        like: 0,
        image: 'https://campingshare-bucket-image.s3.ap-northeast-2.amazonaws.com/1645162028898_camp.png',
        title: '스타렉스 기반 하슬라',
        link: '/product/1',
        reviewRate: 9.3,
        reviewPeople: 32,
        place: '서울시 종로구 가나다로 123',
        driver: '1종 대형',
        people: 13,
        pet: true,
        delivery: true,
        price: 100000,
        priceInfo: '1박',
        state: '예약완료',
        stateColor: 'var(--blue)',
        onLike: () => {},
        discountInfo: {
          hasDiscount: true,
          discountRate: 10,
          beforePrice: 120000,
          discountName: '그린슈머 할인가',
        },
      }}
      /> */}
      {
        data?.map((eachData) => {
          const hasDiscount = eachData.discountPercentage > 0;
          const dayDiff = getDateDiff(LeftDate, RightDate);
          return (
            <NewCarCard info={{
              tags: eachData.feature,
              like: eachData.like ? 1 : 0,
              image: eachData.image,
              title: eachData.title,
              link: dayDiff > 0 ? `/product/${eachData.id}?start=${getDateString(LeftDate as Date)}&end=${getDateString(RightDate as Date)}` : `/product/${eachData.id}`,
              reviewRate: eachData.reviewavg,
              reviewPeople: eachData.reviewcnt,
              place: eachData.location_details,
              driver: eachData.license,
              people: eachData.seats,
              pet: eachData.pet === 1,
              delivery: eachData.delivery === 1,
              price: eachData.price,
              priceInfo: dayDiff > 0 ? `${dayDiff}박` : '1박',
              onLike: () => doLike(eachData.id, eachData.like, () => mutate()),
              discountInfo: hasDiscount
                ? {
                  hasDiscount: true,
                  beforePrice: eachData.originPrice,
                  discountRate: eachData.discountPercentage,
                  discountName: eachData.discount,
                }
                : {
                  hasDiscount: false,
                },
            }}
            />
          );
        })
      }
    </>
  );
};

export default Main;
