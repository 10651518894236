/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import axios from 'axios';
import React from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import MainTitle from '../../Components/MainTitle';
import NewCarCard, { filledStar } from '../../Components/NewCarCard';
import styles from '../../Css/Price.module.css';
import useAdminLogin from '../../Middle/useAdminLogin';
import { detailI } from './Price';

const Review = function () {
  const { id } = useParams();
  const { loginData } = useAdminLogin();
  const { data } = useSWR(id && loginData?.login && `https://api.campingshare.co.kr/admin/product/details/${id}`, async (url: string) => {
    const res = await axios.get<detailI>(url, loginData?.login ? loginData.axiosConfig : {});
    return res.data;
  });
  const { data: reviewData, mutate: reviewMutate } = useSWR(id && loginData?.login && `https://api.campingshare.co.kr/admin/review/list/${id}`, async (url: string) => {
    const res = await axios.get<{
      score: number, details: string, reviewed_date: string, payment_id: string
    }[]>(url, loginData?.login ? loginData.axiosConfig : {});
    return res.data;
  });

  const onDelete = async (pid: string) => {
    if (!confirm('삭제하시겠습니까?')) return;
    try {
      await axios.get(`https://api.campingshare.co.kr/admin/review/delete/${pid}`, loginData?.login ? loginData.axiosConfig : {});
      alert('삭제했습니다');
      reviewMutate();
    } catch {
      alert('오류가 발생했습니다');
    }
  };

  if (!data) return <div />;

  return (
    <div className={styles.priceDiv}>
      <MainTitle>리뷰 관리</MainTitle>
      {
        data && (
          <NewCarCard info={{
            title: data.title, tags: [], like: -1, image: data.image, reviewRate: 9.9, reviewPeople: -1, place: data.location_details, driver: data.license, people: data.seats, pet: data.pet === 1, delivery: data.delivery === 1, price: -1, priceInfo: '이렇게 보입니다', discountInfo: { hasDiscount: false }, onLike: () => {},
          }}
          />
        )
      }
      {
        reviewData?.map((d) => (
          <div className={styles.reviewEachDiv}>
            <div className={styles.reviewRateDiv}>
              {filledStar}
              <div>{`평점: ${d.score}/10`}</div>
              <button type="button" onClick={() => onDelete(d.payment_id)}>삭제하기</button>
            </div>
            <div className={styles.reviewContents}>{d.details}</div>
            <div className={styles.reviewDate}>{d.reviewed_date}</div>
          </div>
        ))
      }
    </div>
  );
};

export default Review;
