import axios from 'axios';
import useLogin from './useLogin';

export const useLike = () => {
  const { loginData } = useLogin();
  return async (idx: number, currentState: boolean, callback?: () => {}) => {
    if (!loginData?.login) {
      alert('로그인을 해주세요');
      return;
    }
    try {
      const res = await axios.get(`https://api.campingshare.co.kr/guest/${currentState ? 'dislike' : 'like'}/${idx}`, loginData.axiosConfig);
      if (callback) callback();
    } catch (e) {
      console.log(e);
    }
  };
};

export default useLike;
