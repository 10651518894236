import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../Css/SellerInfo.module.css';
import BlankMaker from './BlankMaker';

const SellerInfo = function () {
  return (
    <div className={styles.sellerInfoDiv}>
      <div className={styles.navDiv}>
        <Link to="/">홈</Link>
        <Link to="/likelist">찜목록</Link>
        <Link to="/reserved">예약내역</Link>
        <Link to="/login">로그인</Link>
      </div>
      <BlankMaker height={20} />
      <div className={styles.infoDiv}>
        대표 : 김봉수 | 사업자등록번호 : 547-88-01700
        {'\n'}
        (우) 04522 서울특별시 중구 남대문로9길 40 (센터플레이스)Center Place)) 다동 20층 2020-5, 6호
        {'\n'}
        통신판매업번호 : 2019-서울중구-2122 | EMAIL : master@beyondkorea.co.kr
      </div>
      <BlankMaker height={20} />
      <div className={styles.contactDiv}>
        고객지원 : 1661-2840
        {'\n'}
        평일 09:00am ~ 18:00pm (주말 및 공휴일 휴무)
      </div>
      <BlankMaker height={20} />
      <div className={styles.copyright}>ⓒ(주)캠핑쉐어 | ALL RIGHTS RESERVED.</div>
      <BlankMaker height={30} />
    </div>
  );
};

export default SellerInfo;
