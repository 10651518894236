import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MainTitle from '../../Components/MainTitle';
import styles from '../../Css/Login.module.css';
import useLogin from '../../Middle/useLogin';
import BlankMaker from '../../Components/BlankMaker';

const SignUp = function () {
  const { loginData } = useLogin();
  const navigate = useNavigate();
  if (loginData?.login) navigate('/');
  return (
    <>
      <MainTitle>회원가입</MainTitle>
      <BlankMaker height={20} />
      <form
        action="submit"
        onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          const tar = e.target as unknown as {value: string}[];
          const trimed = Array(5).fill(0).map((a, i) => tar[i].value.trim());
          const [name, ID, PW, PWcheck, email] = trimed;
          if (name.length === 0) {
            alert('이름을 입력해주세요');
            return;
          }
          if (ID.length === 0) {
            alert('아이디를 입력해주세요');
            return;
          }
          if (PW.length === 0) {
            alert('비밀번호를 입력해주세요');
            return;
          }
          if (email.length === 0) {
            alert('이메일을 입력해주세요');
            return;
          }
          if (PW !== PWcheck) {
            alert('비밀번호와 비밀번호 확인이 다릅니다');
            return;
          }
          try {
            const res = await axios.post<{exist: boolean}>('https://api.campingshare.co.kr/guest/account/checkid', {
              login_id: ID,
            });
            if (res.data.exist) {
              alert('이미 존재하는 ID입니다');
              return;
            }
          } catch {
            alert('오류가 발생했습니다.');
          }
          try {
            const res = await axios.post<{exist: boolean}>('https://api.campingshare.co.kr/guest/account/checkemail', {
              email,
            });
            if (res.data.exist) {
              alert('이미 존재하는 이메일입니다');
              return;
            }
          } catch {
            alert('오류가 발생했습니다.');
          }
          try {
            await axios.post('https://api.campingshare.co.kr/guest/account/signup', {
              login_id: ID, login_password: PW, email, nickname: name,
            });
            alert('회원가입이 완료됐습니다');
            navigate('/login');
          } catch {
            alert('오류가 발생했습니다.');
          }
        }}
        className={styles.form}
      >
        <input type="text" placeholder="이름" />
        <input type="text" placeholder="ID" />
        <input type="password" placeholder="비밀번호" />
        <input type="password" placeholder="비밀번호 확인" />
        <input type="email" placeholder="이메일" />
        <button type="submit">회원가입</button>
      </form>
      <BlankMaker height={100} />
    </>
  );
};

export default SignUp;
