import React, { ReactNode } from 'react';

const JustBold = function (props:{children:ReactNode}) {
  const { children } = props;
  return (
    <span style={{ fontWeight: 'bold' }}>{children}</span>
  );
};

export default JustBold;
