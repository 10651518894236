import axios from 'axios';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import LittleTitleAndText from '../../Components/LittleTitleAndText';
import MainTitle from '../../Components/MainTitle';
import NewCarCard from '../../Components/NewCarCard';
import useInlineCalendar from '../../Components/useInlineCalendar';
import styles from '../../Css/ProductList.module.css';
import useAdminLogin from '../../Middle/useAdminLogin';

export interface settingI {
  'id': number,
  'title': string,
  'contact': string,
  'location_details': string,
  'deliver_start': string,
  'deliver_end': string,
  'seats': number,
  'return_end': string,
  'admin_id': number,
  'image': string
  license: string,
  pet: number,
  delivery: number
}

const newData = {
  add_hour_price_off_weekday: 0,
  add_hour_price_off_weekend: 0,
  add_hour_price_peak_weekday: 0,
  add_hour_price_peak_weekend: 0,
  concatenation_discount_2n_type: 0,
  concatenation_discount_2n_price: 0,
  concatenation_discount_3n_type: 0,
  concatenation_discount_3n_price: 0,
  concatenation_discount_4n_type: 0,
  concatenation_discount_4n_price: 0,
  concatenation_discount_5n_type: 0,
  concatenation_discount_5n_price: 0,
  contact: '',
  deliver_start: '13:00',
  deliver_end: '15:00',
  return_end: '11:00',
  delivery: 0,
  details: '',
  hour_per_day: 0,
  include_vat: 1,
  location: 1,
  location_details: '픽업 장소 주소',
  min_day_off_weekday: 1,
  min_day_off_weekend: 1,
  min_day_peak_weekday: 1,
  min_day_peak_weekend: 1,
  min_hour_add: 1,
  peak_start: '2022-01-01',
  peak_end: '2022-12-31',
  price_mem1_off_weekday: 0,
  price_mem1_off_weekend: 0,
  price_mem1_peak_weekday: 0,
  price_mem1_peak_weekend: 0,
  price_mem2_off_weekday: 0,
  price_mem2_off_weekend: 0,
  price_mem2_peak_weekday: 0,
  price_mem2_peak_weekend: 0,
  price_mem3_off_weekday: 0,
  price_mem3_off_weekend: 0,
  price_mem3_peak_weekday: 0,
  price_mem3_peak_weekend: 0,
  seats: 1,
  sun_as_weekend: 1,
  fri_as_weekend: 0,
  visible: 0,
  title: '새로운 차량',
  image: 'https://campingshare-bucket-image.s3.ap-northeast-2.amazonaws.com/1645162028898_camp.png',
  delivery_warn: '유료 딜리버리 관련 안내',
  driver_warn: '운전자 정보 관련 안내',
  createdAt: '2022-02-22',
  updatedAt: '2022-02-22T03:28:51.000Z',
  equipment: [],
  option: [],
  event: [],
  terms: '상품이용약관',
  refund: '환불규정',
  license: '2종 보통',
  pet: 1,
  equipment_details: '',
  max_hour_add: 10,
};

const ProductCalendar = function (props: {idx: number}) {
  const { idx } = props;
  const { loginData } = useAdminLogin();
  const { Calendar, YearMonth } = useInlineCalendar(new Date(), false);
  const { data: monthData } = useSWR(`https://api.campingshare.co.kr/admin/product/list/month/reserved/${idx}?month=${YearMonth[0]}-${YearMonth[1] < 10 ? `0${YearMonth[1]}` : YearMonth[1]}`, async (url: string) => {
    const res = await axios.get<string[]>(url, loginData?.login ? loginData.axiosConfig : {});
    return res.data;
  });
  return (
    <div className={styles.productCalendarDiv}>
      <div className={styles.productCalendarTitle}>차량 예약 내역</div>
      <Calendar lis={monthData ? monthData.map((s) => (parseInt(s.split('-')[2], 10))) : []} />
    </div>
  );
};

const ProductList = function () {
  const { loginData } = useAdminLogin();
  const { data, mutate } = useSWR('https://api.campingshare.co.kr/admin/product/list/all', async (url) => {
    const res = await axios.get<settingI[]>(url, loginData?.login ? loginData.axiosConfig : {});
    return res.data;
  });
  const navigate = useNavigate();
  return (
    <div className={styles.productListDiv}>
      <MainTitle>차량 목록</MainTitle>
      <button
        onClick={async () => {
          if (!confirm('새로운 차량을 추가하시겠습니까?')) return;
          try {
            const res = await axios.post<{id: number}>('https://api.campingshare.co.kr/admin/product/new', newData, loginData?.login ? loginData.axiosConfig : {});
            if (confirm('새로운 차량을 추가했습니다. 지금 수정하시겠습니까?')) navigate(`/info/${res.data.id}`);
          } catch (e) {
            alert('오류가 발생했습니다.');
          }
        }}
        className={styles.buttonRed}
        type="button"
      >
        새로운 차량 추가
      </button>
      <BlankMaker height={20} />
      {
        data?.map((d) => (
          <NewCarCard
            info={{
              title: d.title,
              image: d.image,
              place: d.location_details,
              tags: [],
              like: -1,
              reviewPeople: -1,
              reviewRate: 10,
              driver: d.license,
              pet: d.pet === 1,
              people: d.seats,
              delivery: d.delivery === 1,
              price: -1,
              priceInfo: '',
              discountInfo: { hasDiscount: false },
              onLike: () => {},
            }}
          >
            <ProductCalendar idx={d.id} />
            <LittleTitleAndText title="출고 가능 시간" content={`${d.deliver_start} ~ ${d.deliver_end} 까지`} />
            <LittleTitleAndText title="반납 마감 시간" content={`${d.return_end} 까지`} />
            <div className={styles.buttonsDiv}>
              <Link to="/schedule" state={{ data: d }} className={styles.button}>일정 변경</Link>
              <Link to={`/price/${d.id}`} className={styles.button}>가격 변경</Link>
              <Link to={`/option/${d.id}`} className={styles.button}>옵션 변경</Link>
              <Link to={`/info/${d.id}`} className={styles.button}>정보 변경</Link>
              <Link to={`/review/${d.id}`} className={styles.button}>리뷰 관리</Link>
              <button
                onClick={async () => {
                  if (!confirm('정말로 차량을 삭제하시겠습니까?')) return;
                  try {
                    await axios.post<{id: number}>(`https://api.campingshare.co.kr/admin/product/delete/${d.id}`, {}, loginData?.login ? loginData.axiosConfig : {});
                    alert('삭제되었습니다.');
                    mutate();
                  } catch (e) {
                    alert('오류가 발생했습니다.');
                  }
                }}
                className={`${styles.buttonRed}`}
                type="button"
              >
                차량 삭제하기
              </button>
            </div>
          </NewCarCard>
        ))
      }
    </div>
  );
};

export default ProductList;
