import axios from 'axios';
import React, { useState } from 'react';
import useSWR from 'swr';
import BlankMaker from '../../Components/BlankMaker';
import EachTitle from '../../Components/EachTitle';
import LittleTitleAndText from '../../Components/LittleTitleAndText';
import useInlineCalendar from '../../Components/useInlineCalendar';
import getDateDiff from '../../Middle/getDateDiff';
import getDateString from '../../Middle/getDateString';
import useAdminLogin from '../../Middle/useAdminLogin';
import { reservedI } from '../Customer/Reserved';
import styles from '../../Css/ReserveList.module.css';
import NewCarCard from '../../Components/NewCarCard';

export const getStateInfo = (canceled: number, returned: number) => {
  if (canceled === 1) return { text: '취소 완료', color: 'var(--gray)' };
  if (returned === 0) return { text: '예약 완료', color: 'var(--blue)' };
  return { text: '반납 완료', color: 'black' };
};

const ReserveList = function () {
  const { loginData } = useAdminLogin();
  const { Calendar, YearMonth, DateObj } = useInlineCalendar(new Date(), true);
  const [category, setCategory] = useState<'purchased' | 'reserved'>('purchased');
  const { data: monthData } = useSWR(`https://api.campingshare.co.kr/admin/product/list/${category}?month=${YearMonth[0]}-${YearMonth[1] < 10 ? `0${YearMonth[1]}` : YearMonth[1]}`, async (url: string) => {
    const res = await axios.get<string[]>(url, loginData?.login ? loginData.axiosConfig : {});
    return res.data;
  });
  const { data: eachData, mutate: eachMutate } = useSWR(`https://api.campingshare.co.kr/admin/product/list/date/${category}?date=${getDateString(DateObj || new Date())}`, async (url: string) => {
    const res = await axios.get<reservedI[]>(url, loginData?.login ? loginData.axiosConfig : {});
    return res.data;
  });
  return (
    <div className={styles.reserveList}>
      <BlankMaker height={30} />
      <Calendar lis={monthData ? monthData.map((s) => (parseInt(s.split('-')[2], 10))) : []} />
      <div className={styles.buttonsDiv}>
        <button type="button" onClick={() => setCategory('reserved')} className={category === 'reserved' ? styles.active : styles.inactive}>차량 사용</button>
        <button type="button" onClick={() => setCategory('purchased')} className={category === 'purchased' ? styles.active : styles.inactive}>예약 접수</button>
      </div>
      <BlankMaker height={30} />
      <div className={styles.text}>{`${DateObj?.getFullYear()}년 ${(DateObj?.getMonth() || 0) + 1}월 ${DateObj?.getDate()}일`}</div>
      <div className={styles.text}>{`${category === 'purchased' ? '예약 접수' : '차량 사용'} 내역`}</div>
      <BlankMaker height={30} />
      {
        eachData?.map((info) => (
          <NewCarCard info={{
            tags: [],
            like: -1,
            title: info.title,
            image: info.image,
            price: info.price,
            priceInfo: `${getDateDiff(new Date(info.start_date), new Date(info.end_date))}박${info.add_hour === 0 ? '' : ` + ${info.add_hour}시간`}`,
            place: info.location_details,
            state: getStateInfo(info.state_canceled, info.state_return).text,
            stateColor: getStateInfo(info.state_canceled, info.state_return).color,
            reviewPeople: -1,
            reviewRate: 10,
            driver: info.license,
            people: info.seats,
            pet: info.pet === 1,
            delivery: info.delivery === 1,
            discountInfo: { hasDiscount: false },
            onLike: () => {},
          }}
          >
            {
              (info.state_return === 0 && info.state_canceled === 0) && (
                <button
                  type="button"
                  className={styles.returnBtn}
                  onClick={async () => {
                    if (!confirm('반납 처리 하시겠습니까?')) return;
                    try {
                      await axios.get(`https://api.campingshare.co.kr/admin/product/modify/statereturn/${info.payment_id}`, loginData?.login ? loginData.axiosConfig : {});
                      alert('반납 처리 되었습니다');
                      eachMutate();
                    } catch {
                      alert('오류가 발생했습니다');
                    }
                  }}
                >
                  반납 처리

                </button>
              )
            }
            {
              info.state_canceled === 0 && (
                <button
                  type="button"
                  className={styles.cancelBtn}
                  onClick={async () => {
                    if (!confirm('취소 처리 하시겠습니까? 취소 처리 후 금액 환불은 별개로 직접 진행하여야 합니다.')) return;
                    try {
                      await axios.get(`https://api.campingshare.co.kr/admin/product/modify/statecanceled/${info.payment_id}`, loginData?.login ? loginData.axiosConfig : {});
                      alert('취소 처리 되었습니다. 금액 환불은 별개로 직접 진행하여야 합니다.');
                      eachMutate();
                    } catch {
                      alert('오류가 발생했습니다');
                    }
                  }}
                >
                  취소 처리

                </button>
              )
            }
            <LittleTitleAndText title="대여 기간" content={`${info.start_date} ~ ${info.end_date}`} />
            <LittleTitleAndText title="인수 시간" content={`${info.deliver_start} ~ ${info.deliver_end} 까지`} />
            <LittleTitleAndText title="반납 시간" content={`${info.return_end} 까지`} />
            <LittleTitleAndText title="예약자 정보" content={`${info.orderer_details}`} />
            <LittleTitleAndText title="예약자 전화번호" content={`${info.orderer_phone}`} />
            <LittleTitleAndText title="딜리버리 / 픽업 정보" content={`${info.delivery_details}`} />
            <LittleTitleAndText title="추가 장비 정보" content={`${info.equipmentList.length > 0 ? info.equipmentList.map((d) => d.title).join(', ') : '없음'}`} />
          </NewCarCard>

        ))
      }
      {
        (!eachData || eachData.length === 0) && <EachTitle marginTop={10}>예약 내역이 없습니다.</EachTitle>
      }
    </div>
  );
};

export default ReserveList;
