import axios from 'axios';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import EachTitle from '../../Components/EachTitle';
import MainTitle from '../../Components/MainTitle';
import styles from '../../Css/ChangePassword.module.css';
import useAdminLogin from '../../Middle/useAdminLogin';

const ChangePassword = function () {
  const location = useLocation();
  const navigate = useNavigate();
  const { loginData } = useAdminLogin();
  const { state } = (location as {state: { login_id: string, nickname: string }});
  if (!state || !('login_id' in state)) {
    navigate(-1);
    return <div />;
  }
  return (
    <div className={styles.mainDiv}>
      <MainTitle>비밀번호 변경하기</MainTitle>
      <EachTitle marginTop={0} alignCenter>
        <>
          {state.nickname}
          <br />
          {`(${state.login_id})`}
        </>
      </EachTitle>
      <form
        action="submit"
        onSubmit={async (e) => {
          e.preventDefault();
          const newPW = (e.target as any)[0].value;
          const newPWCheck = (e.target as any)[1].value;
          if (newPW !== newPWCheck) {
            alert('비밀번호 확인이 다릅니다');
            return;
          }
          try {
            const res = await axios.post('https://api.campingshare.co.kr/admin/password', {
              login_id: state.login_id,
              after_password: newPW,
            }, loginData?.login ? loginData.axiosConfig : {});
            alert('변경했습니다.');
            navigate('/account');
          } catch {
            alert('오류가 발생했습니다.');
          }
        }}
      >
        <input type="password" placeholder="새로운 비밀번호" />
        <input type="password" placeholder="새로운 비밀번호 확인" />
        <button type="submit">변경하기</button>
      </form>
    </div>
  );
};

export default ChangePassword;
