import React from 'react';
import styles from '../Css/OldHeader.module.css';
import logoGreen from '../Meta/logoGreen.png';

const OldHeader = function () {
  return (
    <div className={styles.headerDiv}>
      <a href="/"><img src={logoGreen} alt="logo" className={styles.logoImg} /></a>
    </div>
  );
};

export default OldHeader;
