/* eslint-disable camelcase */
import axios from 'axios';
import React, { useState } from 'react';
import styles from '../../Css/AdminLogin.module.css';
import useAdminLogin from '../../Middle/useAdminLogin';

const Login = function () {
  const [Loading, setLoading] = useState(false);
  const { loginMutate } = useAdminLogin();
  return (
    <div className={styles.loginDiv}>
      <div>관리자 페이지</div>
      <form onSubmit={async (e) => {
        e.preventDefault();
        if (Loading) return;
        const id: string = (e.currentTarget[0] as any).value;
        const pw: string = (e.currentTarget[1] as any).value;
        setLoading(true);
        try {
          const res = await axios.post<{
            success: boolean, jwt_admin: string
          }>('https://api.campingshare.co.kr/admin/signin', {
            login_id: id, login_password: pw,
          });
          const { data } = res;
          const { success, jwt_admin } = data;
          if (success) {
            localStorage.setItem('jwt_admin', jwt_admin);
            loginMutate();
          } else {
            throw Error;
          }
          setLoading(false);
        } catch {
          alert('잘못된 아이디 또는 비밀번호입니다.');
        } finally {
          setLoading(false);
        }
      }}
      >
        <input type="text" placeholder="아이디" />
        <input type="password" placeholder="비밀번호" />
        <button type="submit" className={`${Loading && styles.loadingButton}`}>로그인</button>
      </form>
    </div>
  );
};

export default Login;
