import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './Components/Header';
import Main from './Pages/Customer/Main';
import Reserved from './Pages/Customer/Reserved';
import styles from './Css/Customer.module.css';
import SellerInfo from './Components/SellerInfo';
import LikeList from './Pages/Customer/LikeList';
import Product from './Pages/Customer/Product';
import Login from './Pages/Customer/Login';
import LoginCallback from './Pages/Customer/LoginCallback';
import PageChangeEvent from './Middle/PageChangeEvent';
import Reserve from './Pages/Customer/Reserve';
import MyInfo from './Pages/Customer/MyInfo';
import Logout from './Pages/Customer/Logout';
import Finish from './Pages/Customer/Finish';
import Error from './Pages/Customer/Error';
import BlankMaker from './Components/BlankMaker';
import SignUp from './Pages/Customer/SignUp';

const Customer = function () {
  return (
    <div className={styles.fullDiv}>
      <PageChangeEvent />
      <Header />
      <BlankMaker height={64} />
      <div className={styles.contentDiv}>
        <Routes>
          <Route path="/likelist" element={<LikeList />} />
          <Route path="/reserved" element={<Reserved />} />
          <Route path="/reserve" element={<Reserve />} />
          <Route path="/myinfo" element={<MyInfo />} />
          <Route path="/error" element={<Error />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/finish/:id" element={<Finish isFinish />} />
          <Route path="/reserved/:id" element={<Finish isFinish={false} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/logincallback" element={<LoginCallback />} />
          <Route path="/" element={<Main />} />
        </Routes>
        <SellerInfo />
      </div>

      {/* <Routes>
        <Route path="/likelist" element={<NavBar now="likelist" />} />
        <Route path="/reserved" element={<NavBar now="reserved" />} />
        <Route path="/myinfo" element={<NavBar now="myinfo" />} />
        <Route path="/product/:id" element={<div />} />
        <Route path="/*" element={<NavBar />} />
        <Route path="/" element={<NavBar now="main" />} />
      </Routes> */}
    </div>
  );
};

export default Customer;
