import React, { ReactElement } from 'react';
import styles from '../Css/MainTitle.module.css';

const MainTitle = function (props:{children:ReactElement|string}) {
  const { children } = props;
  return (
    <div className={styles.mainTitleDiv}>
      {children}
    </div>
  );
};

export default MainTitle;
