import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import MainTitle from '../../Components/MainTitle';
import KakaoLoginImg from '../../Meta/kakao_login_large_narrow.png';
import styles from '../../Css/Login.module.css';
import useLogin from '../../Middle/useLogin';
import BlankMaker from '../../Components/BlankMaker';

const Login = function () {
  const { loginData, loginMutate } = useLogin();
  const navigate = useNavigate();
  if (loginData?.login) navigate('/');
  return (
    <>
      <MainTitle>로그인</MainTitle>
      <BlankMaker height={40} />
      <form
        action="submit"
        onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          const tar = e.target as unknown as {value: string}[];
          const rawId = tar[0].value;
          const rawPW = tar[1].value;
          const ID = rawId.trim();
          const PW = rawPW.trim();
          if (ID.length === 0) {
            alert('아이디를 입력해주세요');
            return;
          }
          if (PW.length === 0) {
            alert('비밀번호를 입력해주세요');
            return;
          }
          try {
            const res = await axios.post<{jwt: string}>('https://api.campingshare.co.kr/guest/account/signin', {
              login_id: ID, login_password: PW,
            });
            localStorage.setItem('jwt', res.data.jwt);
            loginMutate();
            navigate('/');
          } catch {
            alert('잘못된 아이디 또는 비밀번호입니다.');
          }
        }}
        className={styles.form}
      >
        <input type="text" placeholder="ID" />
        <input type="password" placeholder="비밀번호" />
        <button type="submit">로그인</button>
      </form>
      <button
        type="button"
        className={styles.loginBtn}
        onClick={() => {
          (window as any).Kakao.Auth.authorize({
            redirectUri: `${location.origin}/logincallback`,
          });
        }}
      >
        <img
          src={KakaoLoginImg}
          className={styles.loginImg}
          alt="카카오 로그인 버튼"
        />
      </button>
      <BlankMaker height={40} />
      <Link to="/signup">회원가입</Link>
      <BlankMaker height={100} />
    </>
  );
};

export default Login;
