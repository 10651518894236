/* eslint-disable no-nested-ternary */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import OldHeader from './Components/OldHeader';
import styles from './Css/Customer.module.css';
import PageChangeEvent from './Middle/PageChangeEvent';
import useAdminLogin from './Middle/useAdminLogin';
import { useAdminLoginCheck } from './Middle/useLoginCheck';
import IAMAdmin from './Pages/Admin/IAMAdmin';
import Login from './Pages/Admin/Login';
import Master from './Pages/Admin/Master';

const Admin = function () {
  const { loginData } = useAdminLogin();
  const navigate = useNavigate();
  useAdminLoginCheck(() => navigate('/'));
  return (
    <div className={styles.fullDiv}>
      <PageChangeEvent />
      <OldHeader />
      <div className={styles.contentDiv}>
        {
          loginData?.login
            ? (
              loginData.info.master === 1
                ? <Master />
                : <IAMAdmin />
            )
            : <Login />
        }
      </div>
    </div>
  );
};

export default Admin;
