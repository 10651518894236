export const iconInfo: {[name: string]: string} = {
  carseat: '유아용 카시트',
  net: '방충망',
  battery: '충전식 배터리',
  coolwater: '냉수기',
  dirtytank: '오수탱크',
  watertank: '물탱크',
  side: '사이드어닝',
  popuproof: '팝업루프',
  elec: '전기콘센트',
  speaker: '블루투스 스피커',
  skywindow: '스카이창',
  floor: '바닥난방',
  table: '테이블',
  backcam: '후방카메라',
  induction: '인덕션',
  curtain: '암막커튼',
  sleep: '수면용품',
  gasrange: '가스레인지',
  hotwater: '온수기',
  shower: '샤워실',
  air: '배기시설',
  outshower: '외부샤워기',
  roofbox: '루프박스',
  microwave: '전자레인지',
  toilet: '화장실',
  hotmat: '온수매트',
  moniter: '룸미러형 모니터',
  kit: '평탄화 키트',
  aircon: '에어컨',
  sink: '싱크대',
  heater: '무시동 히터',
  bed: '침대',
  blackbox: '블랙박스',
  oiltable: '유압식테이블',
  refrigerator: '냉장고',
  tv: 'TV',
};

export const getIconURL = (url: string) => `/icons/${url}.png`;

export const iconSpliter = '#csicon#';

export default iconInfo;
