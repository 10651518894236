import React from 'react';

const HorizontalLine = function (props:{marginTop:number, marginBottom:number}) {
  const { marginTop, marginBottom } = props;
  return (
    <div style={{
      marginTop: `${marginTop}px`,
      marginBottom: `${marginBottom}px`,
      width: '100%',
      height: '1px',
      background: '#E8E8E8',
    }}
    />
  );
};

export default HorizontalLine;
